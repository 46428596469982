/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.LanguageSwitcher {
  display: flex;
  float: left;
  line-height: 20px;
  position: relative;
  z-index: 3;

  &:before {
    @extend .icomoon-font !optional;
    content: $iconGlobe;
    font-size: 14px;
    line-height: 19px;
  }
}

.LanguageSwitcherOptions {
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-left: 8px;

  :global {

    li {
      cursor: pointer;
      display: inline-block;
      transition: all .3s $defaultBezier;

      &:hover {
        color: $purpleDarker;
      }

      &.active {
        color: $purpleDarker;
        pointer-events: none;
      }

      &:first-child {

        &:before {
          display: none;
        }
      }

      &:before {
        color: $grey;
        content: '|';
        margin: 0 2px;
        position: relative;
        top: -1px;
      }
    }
  }
}
