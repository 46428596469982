.input-range__slider {
  appearance: none;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background: #c92976;
  border: 2px solid #ffffff;
  touch-action: none; }
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none; }

.input-range__label {
  color: #aaaaaa;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
  display: none;}

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -38px; }

.input-range__label-container {
  left: -50%;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 8px;
  background: #f5f5f5;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #000000;
  display: block;
  width: 43px;
  height: 24px;
  text-align: center;
  position: relative; }
.input-range__label--max .input-range__label-container {
  left: 50%; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 2px;
  position: relative; }
.input-range--disabled .input-range__track {
  background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #c92976; }

.input-range {
  height: 2px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 11px;
  position: relative; }
