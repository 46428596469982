/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CustomerAccountWrapper {
  float: right;
  position: relative;
  width: 65px;
  z-index: 8;

  .CustomerAccountIcon {
    color: $grey;
    cursor: pointer;
    text-align: center;
    transition: all .2s $defaultBezier;

    a {
      display: block;
      padding-bottom: 15px;

      &:before {
        @extend .icomoon-font !optional;
        content: $iconUser;
        display: block;
        font-size: 28px;
        margin: 0 auto 6px;
        padding-top: 4px;
      }
    }
  }

  &:hover {

    .CustomerAccountIcon {
      color: $purple;
    }
  }
}

.CustomerAccountLabel {
  bottom: 0;
  font-size: $fontSize-s;
  left: 50%;
  letter-spacing: 0.5px;
  line-height: 20px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  transform: translateX(-50%);
  white-space: nowrap;
  width: 100px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .CustomerAccountWrapper {
    display: none;
  }
}
