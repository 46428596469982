/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Preloader {
  background: $white;
  border-radius: 8px;
  height: 100px;
  position: relative;
  width: 100%;

  &.PreloaderCover {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 3;
  }

  &.PreloaderTransparent {
    opacity: 0.8;
  }

  .PreloaderIconWrapper {
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;

    &:before {

      :global {
        animation: spin .8s infinite linear;
      }
      border: 4px solid $purpleDarker;
      border-color: $purpleDarker transparent transparent transparent;
      border-radius: 50%;
      content: '';
      display: block;
      height: 24px;
      left: 50%;
      margin: -12px 0 0 -12px;
      position: absolute;
      top: 50%;
      width: 24px;
    }
  }
}
