/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ProductBanner {
  border: 1px solid $greyLightest;
  border-radius: 8px;
  margin-bottom: 50px;
  margin-top: 50px;
  position: relative;
}

.ProductBannerRowHeadline > div {
  margin-top: 0;
}

.ProductBannerInfo {
  padding: 32px 0 32px 64px;
  width: 48%;
}

.ProductBannerText {
  font-family: $fontFamily;
  font-size: $fontSize-xxl;
  line-height: 36px;
  margin-bottom: 32px;
}

.ProductBannerButton {
  max-width: 186px;
}

.ProductBannerLabel {
  left: 56%;
  position: absolute;
  top: 14px;
}

.ProductBannerBg {
  background-size: cover;
  border-radius: 8px;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .ProductBanner {
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .ProductBannerInfo {
    padding: 12px 0 24px 24px;
  }

  .ProductBannerText {
    margin-bottom: 24px;
  }

  .ProductBannerLabel {
    left: 50%;
    top: 8px;
  }

  .ProductBannerBg {
    background-position: 68%;
  }

  .ProductBannerButton {
    width: 161px;
  }
}

@media screen and ( $mobile-media ) {

  .ProductBannerBg {
    background-position: center;
  }

  .ProductBannerLabel {
    left: 18px;
    top: 18px;
  }

  .ProductBannerText {
    margin-top: 252px;
  }

  .ProductBannerInfo {
    padding: 12px 24px 32px;
    width: 100%;
  }

  .ProductBanner {
    margin-left: auto;
    margin-right: auto;
    max-width: 420px;
  }
}
