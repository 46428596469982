/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ProductSlider {
  color: $greyDarkest;
  margin-bottom: 72px;
  max-height: 500px;
  padding-top: 15px;

  &.AutoHeight {
    max-height: none;
  }

  :global {

    .keen-slider__slide {
      height: 500px;
      margin-bottom: 5px;
      margin-top: 5px;

      [ class *= 'ProductCardInner' ] {
        align-content: space-between;
        display: flex;
        flex-wrap: wrap;
        height: 495px;
        justify-content: center;
        position: relative;

        > div:not([ class *= 'ProductCardAddToCar' ]) {
          width: 100%;
        }
      }
    }

    .dots {
      bottom: 0;

      .dot {
        background: $greyLightest;
        border: 0;
        height: 12px;
        width: 12px;

        &.active {

          &:after {
            height: 6px;
            width: 6px;
          }
        }
      }
    }

    [ class *= 'ProductCard_ProductCard' ]:hover {

      .Volume__Amount {
        opacity: 1 !important;
      }
    }
  }
}

.SliderHolder {
  position: relative;

  :global {

    .navigation-wrapper {
      margin: 0 -40px;
      padding: 0 40px 40px;

      &:before, &:after {
        background: $white;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 40px;
        z-index: 2;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }
}

.Arrow {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  height: 50px;
  outline: none;
  position: absolute;
  top: 50%;
  transition-duration: 0.4s;
  width: 50px;
  z-index: 3;

  &.ArrowDisabled {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;
  }

  &:after {
    display: none;
  }
}

.ArrowPrev {
  background-image: url($basePathImages + 'slider/arrow-left.svg');
  left: 5px;

  &:not(.ArrowDisabled) {

    &:hover {
      background-position-x: -10px;
    }
  }
}

.ArrowNext {
  background-image: url($basePathImages + 'slider/arrow.svg');
  right: 5px;

  &:not(.ArrowDisabled) {

    &:hover {
      background-position-x: 10px;
    }
  }
}

.ProductSliderTitle {
  font-family: $fontFamily;
  font-size: $fontSize-xxl;
  font-weight: $font-weight-m;
  line-height: 36px;
  margin-bottom: 40px;
  position: relative;
  text-align: center;

  span {
    background: $white;
    padding: 0 17px;
    position: relative;
    z-index: 1;
  }

  &:after {
    background: $greyDarkest;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 56%;
    width: 100%;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .ProductSliderTitle {
    margin-bottom: 32px;
  }

  .ArrowPrev {
    left: 10px;

    &:not(.ArrowDisabled) {

      &:hover {
        background-position-x: -5px;
      }
    }
  }

  .ArrowNext {
    right: 10px;

    &:not(.ArrowDisabled) {

      &:hover {
        background-position-x: 5px;
      }
    }
  }
}

@media screen and ( $mobile-media ) {

  .ProductSliderTitle {
    font-size: $fontSize-xl;
    line-height: normal;
    margin-bottom: 18px;

    span {
      padding: 0 12px;
    }
  }

  .ArrowPrev,
  .ArrowNext {
    display: none !important;
  }
}
