/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.WishlistIconWrapper {
  float: right;
  margin-left: 31px;
  position: relative;
  width: 65px;
}

.WishlistIcon {
  color: $grey;
  cursor: pointer;
  text-align: center;
  transition: all .3s $defaultBezier;

  a {
    display: block;
    padding-bottom: 15px;

    &:before {
      @extend .icomoon-font !optional;
      content: $iconWishlist;
      display: block;
      font-size: 26px;
      margin: 0 auto 8px;
      padding-top: 4px;
    }
  }

  &:hover {
    color: $purple;
  }
}

.WishlistLabel {
  bottom: 0;
  font-size: $fontSize-s;
  left: 50%;
  letter-spacing: 0.5px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 100px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .WishlistIconWrapper {
    margin-left: 0;
    width: auto;
  }

  .WishlistIcon {

    a {

      &:before {
        margin-bottom: 0;
      }
    }
  }

  .WishlistLabel {
    display: none;
  }
}
