/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.VariantWrapper {
  color: $greyLighter;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin: 15px 0;

  .ButtonWrapper {
    padding-left: 7px;
    padding-top: 2px;

    button {
      border-radius: 5px;
      height: 50px;
      width: 50px;
    }
  }

  h3 {
    font-weight: $font-weight-s;
  }
}

.VariantItemWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3px;
  padding-top: 10px;

  label {
    padding: 2px;

    @media all and ( $desktop-only-media ) {
      padding: 4px;
    }

    &:before, &:after {
      content: unset;
    }
  }

  .VariantItem {
    border: 2px solid transparent;
    position: relative;
    transition: 0.2s;

    &:hover,
    &.Active {
      border-color: $purpleDarker;
    }

    .VariantItemImage {
      background: $black;
      display: block;
      height: 50px;
      width: 50px;

      & > * {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .VariantItemColor {
      display: block;
      height: 50px;
      width: 50px;
    }

    .VariantDiscountLabel {
      background: $white;
      bottom: 0;
      color: $greyDarkest;
      font-size: $fontSize-s;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
    }

    .VariantItemDisabled {
      @extend .icomoon-font;
      opacity: 0.2;

      &:before {
        color: $black;
        content: $iconX;
        font-size: $fontSize-Icon48;
        position: absolute;
        z-index: 1;
      }
    }
  }

  .VariantDisabled {
    cursor: not-allowed;
  }
}

.VariantItemListWrapper {
  column-gap: 2px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px 0;
  width: 100%;

  .VariantItem {
    position: relative;

    .VariantItemImage {
      background-color: $black;
      display: block;
      height: 25px;
      width: 25px;

      & > * {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .VariantItemColor {
      display: block;
      height: 25px;
      width: 25px;
    }

    .VariantItemDisabled {
      @extend .icomoon-font;
      opacity: 0.2;

      &:before {
        color: $black;
        content: $iconX;
        font-size: $fontSize-Icon25;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 1;
      }
    }
  }

  .ButtonWrapper {
    opacity: 0.8;
    padding-left: 1px;

    button {
      border-radius: 2px;
      height: 25px;
      padding: 0 0 0 3px;
      width: 25px;
    }
  }
}
