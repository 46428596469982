/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.StockAvailability {
  color: $greyLighter;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 12px 0 10px 20px;
  position: relative;

  &:before {
    border-radius: 50%;
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
  }

  &.StockAvailable {

    &:before {
      background: $asamGreen;
    }
  }

  &.StockNotAvailable {

    &:before {
      background: $orange;
    }
  }

  &.StockAvailableSoon {

    &:before {
      background: $greyLightest;
    }
  }
}
