/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.PreHeaderStatic {
  float: right;
  overflow: hidden;
  text-align: right;
}

.PreHeaderStaticItems,
.PreHeaderStaticAdditional {
  display: inline;
}

.PreHeaderStaticItem {
  display: inline;
  padding-left: 20px;
  padding-right: 33px;
  position: relative;
  vertical-align: top;
  white-space: nowrap;

  &:before {
    @extend .icomoon-font !optional;
    content: $iconChecker;
    display: block;
    font-size: 12px;
    left: 0;
    position: absolute;
    top: 2px;
  }

  &:last-child {
    padding-right: 0;
  }
}

.PaybackLogo {
  display: inline;
  position: relative;
  top: -2px;
  white-space: nowrap;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $custom-1080-media ) {

  .PreHeaderStaticItems {
    display: none;
  }
}
