/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.PriceDateHolder {
  font-size: $fontSize-s;
  font-weight: $font-weight-m;
  letter-spacing: .5px;
  line-height: 20px;
  white-space: nowrap;

  &.AlertClass {
    color: $redError;
  }
}
