/*------------------------------------*\
  #Fonts
\*------------------------------------*/

[class^="icon-"],
[class*=" icon-"],
[class^="after-icon-"]:after,
[class*=" after-icon-"]:after,
[class^="before-icon-"]:before,
[class*=" before-icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.after-icon-chevron-thin-right:after,
.before-icon-chevron-thin-right:before,
.icon-chevron-thin-right:before {
  content: "\e90c";
}

.after-icon-chevron-thin-left:after,
.before-icon-chevron-thin-left:before,
.icon-chevron-thin-left:before {
  content: "\e90d";
}

.after-icon-x:after,
.before-icon-x:before,
.icon-x:before {
  content: "\e90e";
}

.after-icon-check:after,
.before-icon-check:before,
.icon-check:before {
  content: "\e908";
}

.after-icon-basket:after,
.before-icon-basket:before,
.icon-basket:before {
  content: "\e900";
}

.after-icon-globe:after,
.before-icon-globe:before,
.icon-globe:before {
  content: "\e901";
}

.after-icon-checker:after,
.before-icon-checker:before,
.icon-checker:before {
  content: "\e902";
}

.after-icon-chevron-right:after,
.before-icon-chevron-right:before,
.icon-chevron-right:before {
  content: "\e903";
}

.after-icon-chevron-left:after,
.before-icon-chevron-left:before,
.icon-chevron-left:before {
  content: "\e904";
}

.after-icon-chevron-down:after,
.before-icon-chevron-down:before,
.icon-chevron-down:before {
  content: "\e905";
}

.after-icon-chevron-up:after,
.before-icon-chevron-up:before,
.icon-chevron-up:before {
  content: "\e906";
}

.after-icon-search:after,
.before-icon-search:before,
.icon-search:before {
  content: "\e907";
}

.after-icon-wishlist:after,
.before-icon-wishlist:before,
.icon-wishlist:before {
  content: "\e909";
}

.after-icon-user:after,
.before-icon-user:before,
.icon-user:before {
  content: "\e90a";
}

.after-icon-alert-circle:after,
.before-icon-alert-circle:before,
.icon-alert-circle:before {
  content: "\e90b";
}

.after-icon-minus:after,
.before-icon-minus:before,
.icon-minus:before {
  content: "\e90f";
}

.after-icon-plus:after,
.before-icon-plus:before,
.icon-plus:before {
  content: "\e910";
}

.after-icon-logout:after,
.before-icon-logout:before,
.icon-logout:before {
  content: "\e926";
}

.after-icon-lock:after,
.before-icon-lock:before,
.icon-lock:before {
  content: "\e912";
}

.after-icon-facebook:after,
.before-icon-facebook:before,
.icon-facebook:before {
  content: "\e913";
}

.after-icon-account-profile:after,
.before-icon-account-profile:before,
.icon-account-profile:before {
  content: "\e91a";
}

.after-icon-account-counseling:after,
.before-icon-account-counseling:before,
.icon-account-counseling:before {
  content: "\e914";
}

.after-icon-account-recommendation:after,
.before-icon-account-recommendation:before,
.icon-account-recommendation:before {
  content: "\e915";
}

.after-icon-account-newsletter:after,
.before-icon-account-newsletter:before,
.icon-account-newsletter:before {
  content: "\e916";
}

.after-icon-account-rating:after,
.before-icon-account-rating:before,
.icon-account-rating:before {
  content: "\e917";
}

.after-icon-account-wishlist:after,
.before-icon-account-wishlist:before,
.icon-account-wishlist:before {
  content: "\e918";
}

.after-icon-account-package:after,
.before-icon-account-package:before,
.icon-account-package:before {
  content: "\e919";
}

.after-icon-account-abo:after,
.before-icon-account-abo:before,
.icon-account-abo:before {
  content: "\e924";
}

.after-icon-email:after,
.before-icon-email:before,
.icon-email:before {
  content: "\e91b";
}

.after-icon-pinterest:after,
.before-icon-pinterest:before,
.icon-pinterest:before {
  content: "\e91c";
}

.after-icon-youtube:after,
.before-icon-youtube:before,
.icon-youtube:before {
  content: "\e91d";
}

.after-icon-heart:after,
.before-icon-heart:before,
.icon-heart:before {
  content: "\e91e";
}

.after-icon-heart-full:after,
.before-icon-heart-full:before,
.icon-heart-full:before {
  content: "\e91f";
}

.after-icon-present:after,
.before-icon-present:before,
.icon-present:before {
  content: "\e920";
}

.after-icon-hero-left:after,
.before-icon-hero-left:before,
.icon-hero-left:before {
  content: "\e921";
}

.after-icon-hero-right:after,
.before-icon-hero-right:before,
.icon-hero-right:before {
  content: "\e922";
}

.after-icon-apple:after,
.before-icon-apple:before,
.icon-apple:before {
  content: "\e923";
}

.after-icon-basket-plus:after,
.before-icon-basket-plus:before,
.icon-basket-plus:before {
  content: "\e925";
}

.after-icon-paypal:after,
.before-icon-paypal:before,
.icon-paypal:before {
  content: "\e911";
}

.after-icon-amazon:after,
.before-icon-amazon:before,
.icon-amazon:before {
  content: "\ea87";
}

.after-icon-bell:after,
.before-icon-bell:before,
.icon-bell:before {
  content: "\e951";
}