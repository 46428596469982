/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Radio {
  display: flex;
  position: relative;
}

.RadioInput {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: visible;

  &:checked {

    + .RadioLabel {

      &:before {
        border-color: $greyLightest;
      }

      &:after {
        background: $purpleDarker;
        opacity: 1;
      }
    }
  }

  &[ disabled ] {

    + .RadioLabel {
      color: $greyLighterPlus;
      cursor: default;

      &:before {
        border-color: $greyLighterPlus !important;
        border-width: 1px !important;
      }

      &:after {
        background: $greyLighterPlus !important;
        opacity: 0 !important;
      }
    }

    &:checked {

      + .RadioLabel {

        &:after {
          opacity: 1 !important;
        }
      }
    }
  }

  &:focus {

    + .RadioLabel {

      &:before {
        border-color: $purpleDarker;
        border-width: 2px;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.RadioLabel {
  cursor: pointer;
  display: inline-block;
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
  padding-left: 36px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    background: $white;
    border: 1px solid $greyLighterPlus;
    border-radius: 50%;
    box-sizing: border-box;
    height: 24px;
    left: 0;
    transition: all .3s $defaultBezier;
    width: 24px;
  }

  &:after {
    background: $greyLighterPlus;
    border-radius: 50%;
    box-sizing: border-box;
    height: 10px;
    left: 7px;
    opacity: 0;
    transition: all .3s $defaultBezier;
    width: 10px;
  }

  &:hover {

    &:before {
      border-color: $purpleDarker !important;
    }

    &:after {
      opacity: 1;
    }
  }

  &.RadioLabelThin {
    font-weight: $font-weight-m;
  }
}

.RadioIcon {
  line-height: 1;
  position: absolute;
  right: -37px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    height: 24px;
    width: 24px;
  }
}
