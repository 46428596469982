/* ------------------------------------*\
  #Elements
\*------------------------------------ */

.Interactive {
  cursor: pointer;
}

.Underline {
  text-decoration: underline;
}

.Link {
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-decoration: underline;
  transition: all .3s;

  &.LinkPurple {
    color: $purpleDarker;

    svg {

      path {
        stroke: $purpleDarker;
      }
    }
  }

  &.LinkGreyLightest {
    color: $greyLightest;

    svg {

      path {
        stroke: $greyLightest;
      }
    }
  }

  &.LinkLarge {
    font-size: $fontSize-sm;
    letter-spacing: 0;
    line-height: 22px;
  }

  &.LinkWithMediumWeight {
    font-weight: 600;
  }

  &.LinkNoUnderline {
    text-decoration: none;
  }

  &.LinkWithIcon {
    padding-left: 26px;
    position: relative;

    &:before {
      color: $purpleDarker;
      font-size: $fontSize-Icon15;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.LinkWithIconBottom {
    padding-left: 0;
    position: relative;

    &:after {
      bottom: -14px;
      font-size: $fontSize-Icon20;
      left: 50%;
      position: absolute;
      top: initial;
      transform: translateX(-50%);
    }
  }

  &.LinkWithIconTop {
    padding-left: 0;
    position: relative;

    &:after {
      bottom: initial;
      font-size: $fontSize-Icon20;
      left: 50%;
      position: absolute;
      top: -14px;
      transform: translateX(-50%) rotate(180deg);
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &.LinkDisabled {
    opacity: 0.5;
  }
}

.LabelWithDashes {
  text-align: center;

  &.LabelWithDashesCenter {
    margin-left: auto;
    margin-right: auto;
  }

  .LabelWithDashesText {
    color: $greyDarkest;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.Divider {

  &.DividerVertical {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;

    &:after {
      background: $greyLightest;
      content: '';
      height: 20px;
      left: 50%;
      position: absolute;
      top: 3px;
      width: 1px;
    }
  }

  &.DividerHorizontal {
    background: $greyLightest;
    height: 1px;
    position: relative;
    width: 100%;
  }
}

.ErrorMessage {
  color: $redError;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 22px;
  padding-left: 32px;
  position: relative;

  &:before {
    font-size: $fontSize-Icon20;
    left: 0;
    margin-top: -11px;
    top: 50%;
    position: absolute;
  }

  &.ErrorMessageNoPadding {
    padding-left: 0;
  }

  &.ErrorMessageBox {
    background: $redErrorAlpha;
    border: 1px solid $redError;
    border-radius: 6px;
    margin-bottom: 24px;
    padding: 16px 24px;
  }
}

.ColRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Col50 {
  width: calc(50% - 35px);
}

.pp-content {
  background: none !important;
}

.paybackBlue {
  color: $paybackBlue;

  span {

    &:before {
      border-color: $paybackBlue;
      border-right-color: transparent;
      margin: -10px 0 0 -10px;
    }
  }
}

.LoaderSVG {
  display: block;
  width: 50px;
  height: 50px;
  position: relative;
  background: url("../../assets/images/sprites/stylesSprites.svg#loader") no-repeat center;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .Col50 {
    width: 100%;
  }
}
