/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.SearchBar {
  background: $white;
  padding: 0 12px 0 30px;
  position: absolute;
  right: 0;
  top: 1px;
  transition: all .3s $defaultBezier;
  width: 140px;
  z-index: 5;

  &.Active {
    background: $greyLightestPlus;
    overflow: visible;
    padding-left: 50px;
    width: 100%;
    z-index: 6;

    .SearchBarTrigger {
      display: none;
    }

    .SearchBarForm {
      opacity: 1;
      transition: all .2s $defaultBezier;
      visibility: visible;
    }
  }

  .SearchBarIcon {
    background: none;
    border: none;
    cursor: pointer;
    height: 24px;
    left: -32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;

    i {
      margin-left: 0;

      &:after {
        color: $grey;
        font-size: $fontSize-Icon24;
      }
    }
  }

  .SearchBarReset {
    background: none;
    border: none;
    color: $grey;
    cursor: pointer;
    font-size: $fontSize-Icon24;
    height: 40px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }

  &.AsWidget {
    position: relative;
    z-index: 2;
  }
}

.SearchBarLabel {
  border-left: 1px solid $greyDarker;
  color: $greyDarker;
  cursor: pointer;
  line-height: 24px;
  padding-left: 8px;
  position: relative;

  &:before {
    @extend .icomoon-font !optional;
    color: $grey;
    content: $iconSearch;
    cursor: pointer;
    font-size: 24px;
    height: 24px;
    left: -32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.SearchBarTrigger {
  padding: 24px 0 24px 32px;
  position: absolute;
  right: 12px;
  text-align: right;
  top: 0;
  z-index: 8;
}

.SearchBarForm {
  opacity: 0;
  padding-bottom: 16px;
  padding-top: 16px;
  transition: all .2s $defaultBezier;

  .SearchBarIcon {
    left: 25px;

    line {
      stroke: $greyLighter;
    }

    circle {
      stroke: $greyLighter;
    }
  }

  input[ type = 'text' ] {
    font-size: $fontSize-m;
    line-height: 26px;
    padding: 6px 14px;
    width: 84%;
  }
}

.SearchBarClose {
  cursor: pointer;
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;
  padding-bottom: 5px;
  padding-right: 24px;
  padding-top: 5px;
  position: absolute;
  right: 27px;
  text-align: right;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    @extend .icomoon-font;
    color: $purpleDarker;
    content: $iconX;
    display: block;
    font-size: 24px;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .SearchBar {
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;

    &.Active {
      background: $white;
      padding-left: 0;
    }

    .SearchBarIcon {
      left: 8px;
      top: 8px;
      transform: none;
      z-index: 1;
    }
  }

  .SearchBarTrigger {
    display: none;
  }

  .SearchBarForm {
    opacity: 1;
    padding-top: 0;
    visibility: visible;

    input[ type = 'text' ] {
      background: $greyLightestPlus;
      border: 1px solid $greyLightest;
      border-radius: 8px;
      box-sizing: border-box;
      font-size: $fontSize-sm;
      padding: 5px 0 7px 36px;
      width: 100%;

      &:hover {
        background: $greyLightestPlus;
      }

      &:focus {
        background: $white;
      }
    }
  }

  .SearchBarClose {
    display: none;
  }
}
