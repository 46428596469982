/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ProductCard {
  border: 1px solid white;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
  outline: none;
  padding: 16px 8px 8px;
  position: relative;
  text-align: left;
  transition: all .3s $defaultBezier;

  &.ProductListing {
    border-bottom: 0;
    display: inline-block;
    margin-bottom: 16px;
    max-width: none;
    transition: border .3s $defaultBezier;
    vertical-align: top;
    width: 31.2%;

    .ProductCardImage {
      margin-bottom: 2px;
      min-height: 120px;
    }

    .ProductCardDescription {
      color: $greyLighter;
      font-size: $fontSize-s;
      letter-spacing: 0.5px;
      line-height: 20px;
      margin-bottom: 28px;
    }

    .ProductCardVolume {
      margin-bottom: 4px;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.ProductCardInner {
  overflow: hidden;
  position: relative;

  :global {

    .whole-holder {
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      text-indent: -9999px;
      top: 0;
      width: 100%;
      z-index: 3;
    }
  }
}

.ProductCardImage {
  margin-bottom: 20px;
  min-height: 160px;
  position: relative;

  img {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 160px;
    width: 100%;
  }

  > div.ProductCardLoader {
    left: 0;
  }
}

.ProductCardBadge {
  display: inline-block;
  height: 20px;
  margin-bottom: 2px;
  margin-top: -15px;
}

.ProductCardProducer {
  font-size: $fontSize-sm;
  font-weight: $font-weight-s;
  line-height: 19px;
}

.ProductCardName {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  -webkit-line-clamp: 3;
  line-height: 22px;
  max-height: 66px;
  min-height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProductCardVolume,
.ProductCardApplicationType {
  font-size: $fontSize-s;
  font-weight: $font-weight-l;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 5px;
  min-height: 20px;
}

.ProductCardVolume {

  span:last-of-type {
    display: none;
  }
}

.ProductCardApplicationType {
  font-weight: $font-weight-s;

  span + span {
    padding-left: 5px;
    position: relative;

    &:before {
      content: ',';
      left: 0;
      position: absolute;
    }
  }
}

.ProductCardStars {
  margin-bottom: 10px;

  > div {
    margin: 0;

    > div {
      margin-right: auto;
    }
  }
}

.ProductCardAddToCart {
  bottom: 12px;
  position: absolute;
  right: 8px;
  z-index: 4;

  > button, a {
    height: 44px;
    width: 44px;

    > i {
      font-size: 32px;
      left: 4px;
      margin-left: 0;
      position: absolute;
      top: 3px;
    }

    > span:before {
      left: 45%;
      top: 47%;
    }
  }
}

.ProductCardAddToCartFull {
  margin-top: 24px;
}

.ProductCardDetails {
  margin-top: 16px;
  position: relative;
  z-index: 2;
}

.ProductCardLoader {
  background: $white;
  border-radius: 8px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  svg {
    height: auto;
    margin-top: 20px;
    width: 150px;
  }
}

.ProductCardErrors {
  position: relative;
  z-index: 2;
}

.ProductCardTaxReduction {
  color: $purpleDarker;
  display: block;
  font-weight: $font-weight-l;
  text-align: center;
}

.ProductCardOutOfStock {

  .ProductCardImage {

    & > * {
      opacity: 0.4;
    }

    &:after {
      background-color: $greyDarkest;
      color: $white;
      content: attr(data-soldout);
      font-size: $fontSize-m;
      left: 50%;
      line-height: 1em;
      padding: 4px 8px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-15deg);
      white-space: nowrap;

      @media ( $mobile-media ) {
        font-size: $fontSize-sm;
        white-space: unset;
        width: 6rem;
      }
    }
  }

  .ProductCardBadge,
  .ProductCardLabels {
    display: none;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $desktop-only-media ) {

  .ProductCard {

    :global {

      .Label {
        left: 16px;
      }
    }

    &.ProductListing {

      &:hover {

        :global {

          .AddToWishlist {
            opacity: 1;
          }
        }
      }

      .ProductCardVolume {

        span {
          display: block;
          left: 0;
          opacity: 1;
          position: absolute;
          right: 0;
          transition-duration: 0.5s;

          &:nth-child(2) {
            opacity: 0;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 0 6px rgba(33, 34, 36, 0.24);

      .ProductCardName {
        -webkit-line-clamp: 4;
        max-height: 90px;
        transition: max-height 0.4s;
      }

      .ProductCardVolume {

        span {

          &:nth-child(1) {
            opacity: 0;
          }

          &:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }

  .ProductCardDesktop {
    margin: 0 3.1%;
  }
}

@media screen and ( $tablet-media ) and ( $above-mobile-media ) {

  .ProductCard {

    &.ProductListing {
      width: 22.7%;
    }
  }

  .ProductCardTablet {

    &.ProductCardTabletFirst {
      margin: 0 1.5% 0 3.1%;
    }

    &.ProductCardTabletSecond {
      margin: 0 3.1% 0 1.5%;
    }
  }
}

@media screen and ( $mobile-media ) {

  .ProductCard {

    &.ProductListing {
      width: 48%;

      &:nth-child(odd) {
        margin-right: 2%;
      }

      &:nth-child(even) {
        margin-left: 2%;
      }
    }

    .ProductCardVolume {

      span {

        &:nth-child(2){
          display: none;
        }
      }
    }
  }

  .ProductCardDescription {
    display: none;
  }
}

@media screen and ( $custom-370-media ) {

  .ProductCard {

    &.ProductListing {
      width: 100%;

      &:nth-child(odd) {
        margin-right: 0;
      }

      &:nth-child(even) {
        margin-left: 0;
      }
    }
  }
}
