/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.MiniCartItems {
  margin-right: 0;
  margin-top: 20px;
  padding-right: 0;
}

.MiniCartItem {
  border-bottom: 1px solid $greyLightestPlus;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 240px 85px auto;
  padding-bottom: 11px;
  padding-left: 80px;
  padding-top: 16px;
  position: relative;
}

.MiniCartGradient {
  position: relative;

  &:after {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
  }
}

.MiniCartExtraSpace {
  max-height: 340px;

  > ul > li {
    padding-right: 10px;

    &:last-child {
      margin-bottom: 80px;
    }
  }
}

.MiniCartItemImage {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;

  img {
    display: block;
    width: 100%;
  }
}

.MiniCartItemInfo {
  color: $greyDarkest;
  flex: 1;
}

.MiniCartItemName {
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
  padding-right: 15px;
}

.MiniCartItemPrice {
  line-height: 22px;
  text-align: right;
}

.priceColumn {
  text-align: right;

  .discountNewPrice {
    color: $purple;
    font-weight: $font-weight-l;
  }

  .discountOldPrice {
    color: $greyLighter;
    display: inline;
    text-align: right;
    text-decoration: line-through;
  }

  .priceUvp {
    color: $greyLighter;
    font-size: $fontSize-s;
    margin-right: 4px;
  }
}

.MiniCartItemAdditional {
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;

  > span {
    color: $greyLighter;
  }
}

.MiniCartItemActions {
  display: flex;
  line-height: 1;
  margin-top: 9px;
}

.MiniCartTotals {
  position: relative;
}

.MiniCartSummary {
  color: $greyDarkest;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin: 14px 8px 23px;

  p {
    margin-bottom: 3px;

    &:first-child {
      padding-right: 8px;
      text-align: right;
    }

    &:last-child {
      min-width: 72px;
      text-align: right;
    }
  }
}

.MiniCartQty {
  position: relative;
  width: 81px;

  p {
    font-size: $fontSize-xs;
    font-weight: $font-weight-l;
    line-height: 12px;
    margin-bottom: 4px;
    text-align: center;
  }

  :global {

    .ErrorMessage {
      font-size: 12px;
      left: 50%;
      margin-left: -120px;
      padding-left: 0;
      position: absolute;
      text-align: center;
      width: 240px;
    }
  }
}

.MiniCartFreeShipping {
  color: $greyDarkest;
  text-align: center;
}

.MiniCartFreeShippingAmount {
  color: $purpleDarker;
  font-weight: $font-weight-l;
}

.MiniCartFreeShippingContent {
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding-left: 20px;
  position: relative;

  svg {
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
  }
}

.MiniCartProceedToCheckout {
  margin-top: 16px;

  > a {
    display: inline-block;
    margin-left: 50%;
    min-width: 180px;
    transform: translate(-50%, 0px);
    width: auto;
  }
}

.MiniCartGoToCart {
  line-height: 1;
  margin-top: 16px;
  text-align: center;
}

.FreeItemCheckboxHolder {

  label {
    font-size: 12px;
    white-space: nowrap;
  }
}

.WidthAuto {
  width: auto;
}

.DisabledInfo {
  opacity: .3;
  pointer-events: none;
}
