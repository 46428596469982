/*------------------------------------*\
  #Typography
\*------------------------------------*/

h4 {
  font-weight: 600;
  line-height: 20px;
  font-size: 18px;
}

.preventSelection {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}
