/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.SmartBanner {
  align-content: center;
  align-items: center;
  background: $greyLightest;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 8px 8px 8px 0;
  width: 100%;
}

.SmartBannerClose {
  background: none;
  border: none;
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  position: relative;
  text-indent: -9999px;
  width: 50px;

  &:before {
    @extend .icomoon-font;
    color: $black;
    content: $iconX;
    display: block;
    font-size: 19px;
    left: 50%;
    position: absolute;
    text-indent: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.SmartBannerIcon {
  flex: 0 0 50px;
  height: 50px;
  width: 50px;

  img {
    display: block;
    height: 50px;
    width: 100%;
  }
}

.SmartBannerText {
  flex: 1 0 80px;
  font-size: $fontSize-s;
  font-weight: $font-weight-s;
  line-height: 16px;
  padding: 0 10px;
}

.SmartBannerButton {
  background: $purpleDarker;
  border-radius: 24px;
  color: $white;
  font-size: $fontSize-s;
  font-weight: $font-weight-l;
  letter-spacing: .2px;
  line-height: 20px;
  padding: 4px 12px;
  text-transform: uppercase;
}
