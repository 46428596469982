/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Container {
  margin-left: auto;
  margin-right: auto;
  max-width: $width;
  padding-left: $paddingLeft;
  padding-right: $paddingRight;
  width: 100%;

  :global {

    .hideErrorLogo {
      display: none;
    }
  }
}

.ContainerNoPadding {
  padding-left: 0;
  padding-right: 0;
}

.ContainerFlex {
  display: flex;
}

.ContainerRelative {
  position: relative;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Container {

    &.ClearTablet {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media screen and ( $mobile-media ) {

  .Container {
    padding-left: $mobilePaddingLeft;
    padding-right: $mobilePaddingRight;

    &.ClearMobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ContainerNoPadding {
    padding-left: 0;
    padding-right: 0;
  }
}
