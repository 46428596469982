/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.languageSwitcherContent {
  margin-bottom: 7px;
}

.languageSwitcherContentLabel {
  color: $greyDarkest;
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-bottom: 8px;
}

.languageSwitcherContentInMenu {

  .languageSwitcherContentLabel {
    font-size: $fontSize-l;
    line-height: 20px;
  }
}

.languageSwitcherSpacer {
  background: $greyLightest;
  height: 1px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  width: 167px;
}
