/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.TabHeadersContainer {
  border-bottom: 1px solid $greyLightest;
}

.TabHeaders {
  display: flex;
  justify-content: center;
  text-align: center;
}

.TabHeader {
  color: $white;
  cursor: pointer;
  margin: 0 20px;
  padding: 24px 12px;
  position: relative;

  &:before {
    color: $greyDarker;
    content: attr(data-header);
    font-size: $fontSize-m;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s $defaultBezier;
    width: 100%;
  }

  &:after {
    background: $asamGreen;
    border-radius: 1px;
    bottom: 15px;
    content: '';
    height: 2px;
    left: 0;
    pointer-events: none;
    position: absolute;
    transition: all 0.3s $defaultBezier;
    width: 0;
  }

  &.Active, &:hover {

    &:before {
      font-weight: $font-weight-l;
    }

    &:after {
      width: 100%;
    }
  }
}

.TabContent {
  color: $greyDarkest;
  display: none;
  padding-top: 32px;

  &.Active {
    display: block;
  }

  section:first-child {
    margin-top: -40px;
  }

  section > div {
    padding-left: 0;
    padding-right: 0;
  }
}
