/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ReviewsSummary {
  align-items: center;
  display: flex;
  margin-bottom: 6px;
  margin-top: 12px;

  a {
    align-items: center;
    display: flex;
  }
}

.ReviewsStars {
  position: relative;
  width: 78px;
}

.ReviewsStarsHtml {
  background: #e3e3e3;
  height: 14px;
  overflow: hidden;
  position: relative;
  width: 70px;

  &.starGray {

    &:before {
      background: url('../../../../../../assets/images/reviews/star-gray.png') repeat-x 0 0/14px 14px;
    }
  }

  &:before {
    background: url('../../../../../../assets/images/reviews/star.png') repeat-x 0 0/14px 14px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.ReviewsStarsPercent {
  background: #ffc445;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 0;
}

.ReviewsEmptyStars, .ReviewsFullStars {

  svg {
    height: 13px;
    margin-right: 2px;
    width: 14px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ReviewsFullStars {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 0;
}

.ReviewsSummaryMessage {
  color: $greyLighter;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin-left: 9px;
  margin-top: 1px;
}
