/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

$scrollBgColor: #a0a0a5;
$scrollBorderColor: #f4f4f4;

.Dropdown.Select {
  background: $greyLightestPlus;
  border-color: $greyLightest;
  border-radius: 8px;
  transition: all .3s $defaultBezier;

  &:hover {
    border-color: $greyLightest;
  }

  &.DropdownSecondary {
    background: $white;
  }

  &.DropdownInvalid {
    background: $redErrorAlpha;
    border-color: $redError;
  }

  .DropdownLabel {
    color: $greyDarkest;
    font-size: $fontSize-m;
    line-height: 22px;
    padding: 8px 46px 8px 16px;
    text-align: left;

    &.DropdownFirstAsLabel {
      color: $greyLighter;
      font-style: italic;
    }

    &:after {
      @extend .icomoon-font;
      color: $purpleDarker;
      content: $iconChevronDown;
      font-size: $fontSize-lm;
    }

    &[ data-state = 'active' ] {

      &:after {
        content: $iconChevronUp;
      }
    }

    &:after,
    svg {
      //height: 16px;
      //margin-top: -8px;
      position: absolute;
      right: 14px;
      //stroke: $purpleDarker;
      top: 50%;
      transform: translateY(-50%);
      //width: 16px;
    }
  }

  .DropdownMenu {
    background: $white;
    border: 2px solid $greyLightestPlus;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(33, 34, 36, 0.24);
    margin-top: 4px;
    max-height: 300px;
    overflow: auto;
    width: 100%;

    &.DropdownMenuTertiary {
      left: unset;
      width: auto;
    }
  }

  &.DropdownListFullWidth {

    .DropdownMenu {
      width: 100%;
    }
  }

  .DropdownMenuItem {
    color: $greyDarkest;
    font-size: $fontSize-m;
    line-height: 22px;
    padding: 4px 24px 4px 12px;
    position: relative;
    text-align: left;

    &.DropdownMenuItemTertiary {
      padding: 5px 25px;
      text-align: right;
      white-space: nowrap;
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:hover {
      background: $purpleDarker;
      color: $white;
    }
  }

  &.DropdownSmall {

    .DropdownLabel {
      font-size: $fontSize-sm;
      padding: 5px 16px;
    }

    .DropdownMenuItem {
      font-size: $fontSize-sm;
    }
  }

  &.DropdownLarge {

    .DropdownLabel {
      font-size: $fontSize-l;
      line-height: 20px;
      padding: 14px 16px;
    }

    .DropdownMenuItem {
      font-size: $fontSize-l;
      padding: 8px 0 8px 25px;
    }
  }

  &.DropdownTiny {

    .DropdownLabel {
      font-size: $fontSize-sm;
      padding: 1px 16px;

      svg {
        right: 10px;
      }
    }
  }

  &.DropdownBoldLabel {

    .DropdownLabel {
      font-weight: $font-weight-l;
    }
  }

  .DropdownMenuItemSelected {
    color: $black;
    font-weight: $font-weight-l;
    position: relative;

    &:after {
      @extend .icomoon-font !optional;
      content: $iconCheck;
      font-size: 14px;
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .DropDownMenuBestSellerItem {
    position: relative;

    &:after {
      background: $purple;
      border-radius: 25%;
      content: '';
      height: 22px;
      left: 1px;
      opacity: .2;
      position: absolute;
      top: 1px;
      transform: rotate(3deg);
      width: 100%;
    }
  }

  .DropdownMenuItemSelectedIconTertiary {

    /* &:after {
      @extend .icomoon-font !optional;
      content: $iconCheck;
      font-size: 14px;
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
    } */
  }

  .DropdownMenuItemSelectedIcon {
    height: 9px;
    left: 7px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;

    &.DropdownMenuItemSelectedIconTertiary {
      left: unset;
      right: 7px;
    }
  }

  .DropdownTitle {
    color: $grey;
    font-size: $fontSize-xs;
    left: 14px;
    line-height: 12px;
    padding: 0 3px;
    pointer-events: none;
    position: absolute;
    top: -7px;
    transition: all .3s $defaultBezier;
    z-index: 2;

    &:after {
      background: $white;
      bottom: 4px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }

  &.DropdownNumber {

    .DropdownLabel {
      padding: 5px 16px 5px 12px;
      text-align: center;

      div {
        border-right: 1px solid $greyLightest;
        flex: none;
        padding-right: 7px;
        width: 54%;
      }
    }
  }

  &.DropdownWithMargin {
    margin-bottom: 6px;
  }

  /* total width */

  ::-webkit-scrollbar {
    background-color: $white;
    height: 16px;
    width: 16px;
    z-index: 999999;
  }

  /* background of the scrollbar except button or resizer */

  ::-webkit-scrollbar-track {
    background-color: $white;
  }

  /* scrollbar itself */

  ::-webkit-scrollbar-thumb {
    background-color: $white;
    border: 0 solid $white;
    border-radius: 16px;
  }

  /* set button(top and bottom of the scrollbar) */

  ::-webkit-scrollbar-button {
    display: none;
  }

  /* scrollbar when element is hovered */

  :hover::-webkit-scrollbar-thumb {
    background-color: $scrollBgColor;
    border: 4px solid $white;
  }

  /* scrollbar when scrollbar is hovered */

  ::-webkit-scrollbar-thumb:hover {
    background-color: $scrollBgColor;
    border: 4px solid $scrollBorderColor;
  }
}

.Dropdown.Select.DropDownWithExtraLabel {
  min-width: 232px;
}

.TrackV {
  border-radius: 4px;
  height: 100%;
  right: 0;
  top: 0;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .Dropdown.Select.DropdownNarrowerMobile .DropdownLabel {
    padding: 8px 26px 8px 10px;
  }
}
