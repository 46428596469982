/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ProductLabels {
  position: absolute;
  right: 20px;
  top: 16px;
  z-index: 3;

  > div {
    margin-bottom: 10px;
  }
}

.HideOnDesktop {
  display: none;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .ProductLabels {
    right: 16px;
  }

  .HideOnDesktop {
    display: block;
  }

  .HideOnTablet {
    display: none;
  }
}

@media screen and ( $mobile-media ) {

  .ProductLabels {
    right: 4px;
  }

  .HideOnTablet {
    display: block;
  }

  .HideOnMobile {
    display: none;
  }
}
