/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.TotalRowBold {
  font-weight: $font-weight-l;
}

.TotalRowGrey {
  color: $greyLighter;
}

.TotalRowPink {
  color: $purple;

  .discountApplied {
    padding-bottom: 25px;
  }
}

.TotalRowLeft {
  margin-bottom: 3px;
  margin-right: 10px;
  position: relative;
  text-align: right;

  &.TotalRowRightPadding {
    padding-right: 20px;
  }
}

.TotalRowRight {
  font-size: $fontSize-m;
  margin-left: 16px;
  text-align: right;
  width: 85px;
}
