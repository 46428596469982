/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Overlay {
  margin-top: -5px;
  opacity: 0;
  padding-top: 15px;
  pointer-events: none;
  position: absolute;
  transition: margin-top .2s $defaultBezier, opacity .2s $defaultBezier, visibility .2s $defaultBezier;
  visibility: hidden;

  &.OverlayVisible {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
}

.OverlayPreventBar {
  height: 20px;
  left: 0;
  position: absolute;
  top: -20px;
  width: 100%;
}

.OverlayContent {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(33, 34, 36, 0.24);
  padding: 25px;
  pointer-events: auto;
  position: relative;
}

.OverlayArrow {
  height: 15px;
  pointer-events: none;
  position: absolute;
  top: 1px;
  width: 16px;

  &:after {
    box-shadow: 0 0 8px rgba(33, 34, 36, 0.74);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-62deg);
    width: 11px;
    z-index: 1;
  }

  &:before {
    box-shadow: 0 0 8px rgba(33, 34, 36, 0.74);
    content: '';
    height: 1px;
    position: absolute;
    right: 0;
    top: 7px;
    transform: rotate(62deg);
    width: 11px;
    z-index: 1;
  }
}

.OverlayArrowBg {
  border-color: $transparent $transparent $white;
  border-style: solid;
  border-width: 0 8px 15px;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 2;
}

.OverlayRight {
  margin-left: auto;
  margin-right: 0;

  .OverlayArrow {
    margin-left: auto;
    margin-right: -13px;
  }
}
