/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CustomOption {
  align-items: center;
  display: flex;
  padding: 16px 0 16px 8px;
}

.CustomOptionTitle {
  color: $greyDarkest;
  font-size: 12px;
  font-weight: $font-weight-l;
  line-height: 22px;
  padding-right: 24px;
}

.CustomOptionInput {
  flex: 1;
}
