/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Label {
  border-radius: 50%;
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  height: 62px;
  position: absolute;
  text-align: center;
  width: 62px;
  z-index: 1;

  &:before {
    background: transparent;
    border: 2px solid;
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &:after {
    border-radius: 50%;
    content: '';
    height: calc(100% - 6px);
    left: 3px;
    position: absolute;
    top: 3px;
    width: calc(100% - 6px);
  }
}

.LabelContent {
  align-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: 58px;
  justify-content: center;
  left: 50%;
  line-height: 1;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 58px;
  z-index: 2;
}

.LabelRelative {
  position: relative;
}

.LabelTopLeft {
  left: 0;
  top: 0;
}

.LabelTopRight {
  right: 0;
  top: 0;
}

.LabelBottomLeft {
  bottom: 0;
  left: 0;
}

.LabelBottomRight {
  bottom: 0;
  right: 0;
}

.LabelGrey {
  color: $white;

  &:before {
    border-color: $greyDarker;
  }

  &:after {
    background: $greyDarker;
  }
}

.LabelBlack {
  color: $white;

  &:before {
    border-color: $black;
  }

  &:after {
    background: $black;
  }
}

.LabelDarkGreen {
  color: $white;

  &:before {
    border-color: $darkGreen;
  }

  &:after {
    background: $darkGreen;
  }
}

.LabelOrange {
  color: $white;

  &:before {
    border-color: $orangeDarker;
  }

  &:after {
    background: $orangeDarker;
  }
}

.LabelPurple {
  color: $white;

  &:before {
    border-color: $purpleDarker;
  }

  &:after {
    background: $purpleDarker;
  }
}

.LabelGreen {
  color: $white;

  &:before {
    border-color: $asamGreenDarker;
  }

  &:after {
    background: $asamGreenDarker;
  }
}

.LabelSkin {
  color: $white;

  &:before {
    border-color: $skinDarkestPlus;
  }

  &:after {
    background: $skinDarkestPlus;
  }
}

.LabelNone {
  background: none;
  border-radius: initial;

  .LabelContent {
    border: none;
  }
}

.LabelPrice {

  .LabelContentPrices {
    line-height: 24px;
    width: 100%;

    div {

      div:first-child {
        color: $greyDarkest;
        font-size: $fontSize-l;
        letter-spacing: -0.04em;
        line-height: 18px;
        text-align: center;
      }

      div:last-child {
        color: $white;
        font-size: $fontSize-lm;
      }
    }
  }

  .LabelContentPricesSpecial {

    div {

      *:not(:last-child) {
        color: $greyDarkest;
      }

      div:first-child {
        text-decoration-line: line-through;
      }
    }
  }

  .LabelContentPricesChf {

    div {

      div:first-child {
        font-size: $fontSize-sm;
      }

      div:last-child {
        font-size: $fontSize-l;
      }
    }
  }
}

.LabelPricePerUnit {
  background: transparent;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.5);
  height: 123px;
  padding: 6px;
  position: relative;
  width: 123px;

  &:before {
    border-width: 5px;
  }

  &:after {
    height: calc(100% - 14px);
    left: 7px;
    top: 7px;
    width: calc(100% - 14px);
  }

  .LabelContent {
    height: calc(100% - 14px);
    line-height: 32px;
    width: 100%;
  }
}

.LabelPricePerUnitValue {
  font-size: $fontSize-xs;
  line-height: 12px;
  margin-top: 3px;
}

.LabelLarge {
  height: 104px;
  width: 104px;

  &:before {
    border-width: 5px;
  }

  &:after {
    height: calc(100% - 14px);
    left: 7px;
    top: 7px;
    width: calc(100% - 14px);
  }

  .LabelContent {
    height: calc(100% - 14px);
    line-height: 32px;
    width: calc(100% - 14px);
  }
}

.LabelRotated {
  transform: rotate(-15deg);
}

.LabelTransparent {
  opacity: 0.85;
}

.LabelContentText {
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  left: 0;
  line-height: 12px;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.LabelEnlargeContentText {
  font-size: $fontSize-m;
}

.LabelImage {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.LabelSalePercent {

  .LabelContent {
    font-size: 14px;
    line-height: 15px;
  }
}

.fromText {
  display: block;
  font-weight: $font-weight-s;
  line-height: 20px;
  margin-top: -20px;
}

.upToText {
  display: block;
  font-size: 12px;
  font-weight: $font-weight-s;
  line-height: 20px;
  margin-top: -10px;
}
