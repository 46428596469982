.keen-slider:not([data-keen-slider-disabled]) {
  align-content: flex-start;
  display: flex;
  // overflow: hidden;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  transition-property: transform;
  z-index: 1;

  .keen-slider__slide {
    position: relative;
    //overflow: hidden;
    width: 100%;
    min-height: 100%;
  }

  &[data-keen-slider-reverse] {
    flex-direction: row-reverse;
  }

  &[data-keen-slider-v] {
    flex-wrap: wrap;
  }

  &[data-keen-slider-moves] * {
    pointer-events: none;
  }
}

.keen-slider__slide {

}

.navigation-wrapper {
  overflow: hidden;
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.dot {
  background: $greyLightest;
  border: 1px solid $purpleDarker;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 16px;

  &.active {
    border: none;

    &:after {
      width: 8px;
      height: 8px;
      background: $purpleDarkest;
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;

  &--left {
    left: 5px;
  }

  &--right {
    left: auto;
    right: 5px;
  }

  &--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }
}
