/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

@keyframes pulse {

  from {
    box-shadow: 0 0 0 0 rgba(201, 41, 105, 0.2);
  }

  to {
    box-shadow: 0 0 0 8px rgba(201, 41, 105, 0.2);
  }
}

.MobileMenuOverlay {
  background: $black;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity .3s $defaultBezier;
  width: 100%;
  z-index: 9998;

  &.MobileMenuOpened {
    opacity: 0.5 !important;
    pointer-events: initial;
  }
}

.MobileMenu {
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(33, 34, 36, 0.24);
  color: $greyDarkest;
  height: 100%;
  left: 0;
  max-width: 375px;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all .3s $defaultBezier;
  width: 100%;
  z-index: 9999;

  &.MobileMenuOpened {
    transform: translateX(0) !important;
  }
}

.MobileMenuHideBadge {
  z-index: 1 !important;
}

.MobileMenuHeader {
  background: $white;
  box-shadow: 0 1px 4px rgba(33, 34, 36, 0.24);
  padding: 23px 0;
  position: relative;
  z-index: 5;
}

.MobileMenuLogo {
  line-height: 1;
  text-align: center;
}

.MobileMenuClose {
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;
  padding-right: 24px;
  position: absolute;
  right: 20px;
  text-align: right;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    @extend .icomoon-font;
    color: $purple;
    content: $iconX;
    display: block;
    font-size: 24px;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.MobileMenuContent {
  height: 100%;
  left: 0;
  overflow-y: scroll;
  padding: 33px 24px 275px;
  position: absolute;
  width: 100%;

  > div:first-child,
  > ul:first-child {
    margin-bottom: 60px;
  }

  &.NoTopPadding {
    padding-top: 0;
  }

  &.MobileMenuFirstLevel, &.MobileMenuSecondLevel, &.MobileMenuService, &.MobileMenuStore {
    background: $white;
    z-index: 2;
  }

  &.MobileMenuService, &.MobileMenuStore {
    height: 100%;
    z-index: 4;

    > div:first-child,
    > ul:first-child {
      margin-bottom: 0;
    }
  }

  &.MobileMenuExtendedContent {
    height: calc(100% - 195px);
  }
}

.MobileMenuListItem {
  border-bottom: 1px solid $greyLightest;
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
  padding: 12px 0;
  position: relative;
  text-transform: uppercase;

  &:last-child {
    border-bottom: 0;
  }

  &.Purple {
    color: $purpleDarker;
  }

  span {
    position: relative;
  }

  a {
    display: block;
  }

  &.MobileMenuListItemCustomer {

    &:before {
      content: $iconUser;
      font-size: 19px;
      left: 4px;
    }
  }

  &.MobileMenuListItemFaq {

    &:before {
      content: $iconCheck;
      font-size: 18px;
      left: 4px;
    }
  }

  &.MobileMenuListItemPayback {
    padding-left: 4px !important;

    img {
      margin-right: 6px;
      vertical-align: bottom;
      width: 21px;
    }
  }

  &.MobileMenuListItemLanguage {

    &:before {
      content: $iconGlobe;
      font-size: 18px;
      left: 1px;
    }

    > ul {
      margin-left: 0;
    }
  }
}

.MobileMenuListItemHasSub {

  &:after {
    @extend .icomoon-font;
    color: $purpleDarker;
    content: $iconChevronRight;
    display: block;
    font-size: 17px;
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.MobileMenuItemHighlighted {

  span {
    display: inline-block;
    position: relative;

    &:after {
      background: $purple;
      border-radius: 25%;
      content: '';
      height: 14px;
      left: 0;
      opacity: .2;
      position: absolute;
      top: 50%;
      transform: rotate(2deg) translateY(-50%);
      width: 100%;
    }
  }
}

.MobileMenuListItemLive {
  padding-left: 32px;

  &:before {
    animation-direction: alternate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    animation-timing-function: ease-in-out;
    background: $purpleDarker;
    border-radius: 50%;
    content: '';
    height: 12px;
    left: 8px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    width: 12px !important;
    z-index: 1;
  }
}

.SpecialIcon {
  height: 16px;
  position: absolute;
  right: -25px;
  top: 45%;
  transform: translateY(-50%);
  width: 16px;
}

.MobileMenuAdditionalContent {
  background: $greyLightestPlus;
  bottom: 0;
  left: 0;
  padding: 9px 24px 16px;
  position: absolute;
  width: 100%;
  z-index: 3;

  &:before {
    background: linear-gradient(0deg, #ffffff, rgba(255, 255, 255, 0));
    content: '';
    height: 60px;
    left: -24px;
    pointer-events: none;
    position: absolute;
    top: -60px;
    width: calc(100% + 24px);
  }

  .MobileMenuListItem {
    padding-left: 32px;

    &:before {
      @extend .icomoon-font;
      color: $greyDarkest;
      display: block;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      @extend .icomoon-font;
      color: $purpleDarker;
      content: $iconChevronRight;
      display: block;
      font-size: 17px;
      pointer-events: none;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.MobileMenuListItemLanguage {

      &:after {
        display: none;
      }
    }
  }
}

.LoginButton {
  margin-top: 13px;
}

.LogoutButton {
  margin-top: 16px;

  a {
    appearance: none;
    background: $greyLightestPlus;
    border: 2px solid $greyLightest;
    border-radius: 24px;
    color: $greyDarkest;
    cursor: pointer;
    display: block;
    font-family: $fontFamily;
    font-size: $fontSize-sm;
    font-weight: $font-weight-l;
    line-height: 22px;
    min-height: 34px;
    padding: 4px 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .4s $defaultBezier;
    width: 100%;

    &:focus {
      border-color: $greyMiddle;
      outline: none;
    }

    svg {
      fill: $greyDarkest;
      height: 16px;
      margin-left: 6px;
      vertical-align: -3px;
      width: 16px;
    }
  }
}

.MobileMenuRootLevel, .MobileMenuFirstLevel, .MobileMenuSecondLevel, .MobileMenuService, .MobileMenuStore {
  transform: translateX(-120%);
  transition: all .4s $defaultBezier;
}

.RootLevelActive {

  .MobileMenuRootLevel {
    transform: translateX(0);
  }
}

.FirstLevelActive {

  .MobileMenuFirstLevel {
    transform: translateX(0);
  }
}

.SecondLevelActive {

  .MobileMenuSecondLevel {
    transform: translateX(0);
  }
}

.ServiceActive {

  .MobileMenuService {
    transform: translateX(0);
  }
}

.StoreActive {

  .MobileMenuStore {
    transform: translateX(0);
  }
}

.MobileMenuTitle {
  border-bottom: 1px solid $greyLightestPlus;
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin: 0 0 0 -24px;
  padding: 12px 24px;
  position: relative;
  text-transform: uppercase;
}

.MobileMenuBack {
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-top: 5px;
  padding: 12px 0 12px 32px;
  position: relative;
  text-transform: uppercase;

  &:before {
    @extend .icomoon-font;
    color: $purpleDarker;
    content: $iconChevronLeft;
    display: block;
    font-size: 26px;
    left: -2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.MobileMenuBackToRoot {
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  left: 20px;
  line-height: 12px;
  padding-left: 23px;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);

  &:before {
    @extend .icomoon-font;
    color: $purpleDarker;
    content: $iconChevronLeft;
    display: block;
    font-size: 26px;
    left: -8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.MobileMenuTrusted {
  left: 15px;
  position: absolute;
  top: 10px;
}

.MobileMenuAll {
  border-top: 1px solid $greyLightest;
  color: $purple;
  font-size: $fontSize-s;
  font-weight: $font-weight-l;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 9px 0;
  text-align: left;
}

.MobileMenuSimpleListItem {
  font-size: $fontSize-sm;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 2px;
  padding: 9px 0;

  a {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.MobileMenuServiceContent {
  font-size: $fontSize-sm;
  line-height: 22px;
  margin-bottom: 60px;
  padding-top: 30px;
  text-align: center;

  .Bold {
    font-size: $fontSize-l;
    font-weight: $font-weight-l;
    line-height: 20px;
  }

  .Small {
    color: $greyLighter;
    font-size: $fontSize-s;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-top: 8px;
    text-align: center;
  }

  .Hours {
    margin-bottom: 32px;
    margin-top: 30px;
  }

  button, a {
    margin-left: auto;
    margin-right: auto;
    width: 167px;
  }
}

.MobileMenuStoreContent {
  padding-top: 30px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $desktop-only-media ) {

  .MobileMenuOverlay, .MobileMenu {
    display: none;
  }
}

@media screen and ( $tablet-media ) {

  .MobileMenuHeader {
    min-height: 64px;
  }
}

@media screen and ( $mobile-media ) {

  .MobileMenuHeader {
    min-height: 48px;
    padding: 15px 0;
  }

  .MobileMenuContent {
    height: 100%;
    padding: 0 17px 235px;
  }

  .MobileMenuListItem {
    font-size: $fontSize-s;
    letter-spacing: 0.5px;
    line-height: 20px;
    padding: 9px 0;
  }

  .MobileMenuAdditionalContent {
    padding-left: 17px;
    padding-right: 17px;

    &:before {
      left: -17px;
      width: calc(100% + 17px);
    }
  }

  .MobileMenuTitle, .MobileMenuBack {
    font-size: $fontSize-s;
    letter-spacing: 0.5px;
    line-height: 20px;
    padding-bottom: 9px;
    padding-top: 9px;
  }

  .MobileMenuListItemLive {
    padding-left: 32px;
  }
}

@media screen and ( $custom-350-media ) {

  .MobileMenuClose {
    height: 16px;
    overflow: hidden;
    padding: 0;
    text-indent: 21px;
    width: 15px;
  }

  .MobileMenuBackToRoot {
    height: 16px;
    overflow: hidden;
    padding: 0;
    text-indent: 21px;
    width: 15px;
  }
}
