/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.LeftForFreeshipping,
.LeftForFreeshippingContext {
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-left: 15px;
  position: relative;
  text-align: center;

  .Text {
    padding-left: 20px;
    position: relative;

    &:before {
      font-size: $fontSize-Icon16;
      left: 0;
      position: absolute;
      top: 1px;
    }
  }

  :global {

    .PriceText {
      color: $purple;
      font-weight: $font-weight-l;
    }
  }
}

.LeftForFreeshippingContext {
  max-width: 450px;
  text-align: left;

  .Text {
    display: block;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .LeftForFreeshipping,
  .LeftForFreeshippingContext {
    margin-left: 0;
    max-width: unset;
    text-align: center;

    .Text {
      display: inline-block;
    }
  }
}
