/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ProductCardPriceBox {
  margin-bottom: 5px;
  padding-top: 24px;
  position: relative;

  .ProductCardPrice {
    display: inline-block;
    font-size: $fontSize-l;
    font-weight: $font-weight-l;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .ProductCardPriceUvp {
    font-size: $fontSize-s;
    margin-right: 4px;
  }

  .ProductCardPriceSpecial {
    color: $greyLighter;
    font-size: $fontSize-sm;
    font-weight: $font-weight-l;
    line-height: 22px;
    position: absolute;
    top: 0;
    width: 100%;

    + .ProductCardPrice {
      color: $purpleDarker;
    }

    .ProductCardPriceSpecialShow {
      text-decoration: line-through;
    }
  }
}

.ProductCardPricePerVolume {
  color: $greyLighter;
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-right: 52px;
  min-height: 20px;
}

.ProductCardAdditional {
  color: $greyLighter;
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;
  margin: 0 52px 14px 0;
  position: relative;
}
