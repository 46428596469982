/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.PreHeader {
  background: $greyLightestPlus;
  color: $grey;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  min-height: 32px;
  padding: 6px 0;
  position: relative;
  z-index: 11;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .PreHeader {
    display: none;
  }
}
