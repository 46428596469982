/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsRowHeadline {
  line-height: 36px;
  margin-top: 50px;
  padding-top: 20px;
  position: relative;
  text-align: center;
  width: 100%;

  h2,
  span {
    background: $white;
    display: inline-block;
    font-family: $fontFamily;
    font-size: $fontSize-xxl;
    font-weight: $font-weight-m;
    line-height: 1.3;
    margin: 0 auto;
    max-width: 85%;
    padding: 0 15px;
    position: relative;
    z-index: 1;
  }

  hr {
    border-bottom: 1px solid $greyDarkest;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 65%;
    width: 100%;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .CmsRowHeadline {
    font-size: $fontSize-xl;
    line-height: normal;
  }
}
