/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.SignInOverlay {
  color: $greyDarkest;

  &:after {
    clear: both;
    content: '';
    display: table;
  }

  .NewCustomerHeadline {
    margin-bottom: 32px;
  }
}

.SignInOverlaySection {
  float: left;
  width: 50%;

  &:first-child {
    padding-right: 30px;
  }

  &:last-child {
    padding-left: 30px;
    position: relative;

    &:after {
      background: $greyLightest;
      bottom: 11px;
      content: '';
      height: 88%;
      left: 0;
      position: absolute;
      width: 1px;
    }
  }
}

.NewCustomerHeader {
  line-height: 22px;
  margin-bottom: 28px;
}

.NewCustomerHeadline {
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  line-height: 20px;
}

.NewCustomerList {
  margin-bottom: 20px !important;
  padding-left: 27px !important;
  position: relative;
}

.NewCustomerListItem {
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-bottom: 9px;
  position: relative;

  &:before {
    @extend .icomoon-font !optional;
    color: $asamGreenDarkest;
    content: $iconCheck;
    font-size: 16px;
    left: -24px;
    position: absolute;
    top: 4px;
  }
}
