/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.GalleryPlayButton {
  background-color: $transparent !important;
  height: auto !important;
  margin: auto;
  padding: 0;

  &:hover,
  &:hover:enabled {
    background: transparent;
  }
}

.CmsVideoPlayButton {
  background-color: $transparent !important;
  bottom: 0;
  height: auto !important;
  left: 0;
  margin: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
