/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsRichtext {
  hyphens: auto;
  overflow: hidden;

  h2 {
    font-size: $fontSize-xl;
    font-style: normal;
    font-weight: 100;
    line-height: 1.2;
    margin-bottom: 5px;

    strong {
      font-weight: $font-weight-m;
    }
  }

  h3,
  h4 {
    font-size: $fontSize-l;
    font-style: normal;
    font-weight: $font-weight-m;

    strong {
      font-weight: $font-weight-l;
    }
  }

  h2,
  h3 {
    font-family: $fontFamily;
  }

  h4 {
    font-weight: $font-weight-s;
  }

  h5 {
    font-weight: $font-weight-m;
  }

  h4,
  h5 {
    margin-bottom: 5px;
  }

  p {
    font-size: $fontSize-sm;
    line-height: 22px;
    min-height: 20px;
    padding-bottom: 15px;

    + p,
    + ul,
    + ol {
      margin-top: -10px;
    }

    b {
      font-weight: $font-weight-l;
    }
  }

  blockquote {
    border-left: 2px solid $greyDarker;
    margin-bottom: 15px;
    padding: 8px 0 8px 24px;
    position: relative;

    p {
      font-size: $fontSize-m;
      font-style: italic;
      line-height: 24px;
      padding: 0;
    }
  }

  a {
    color: $purple;
    text-decoration: underline;
    word-break: break-all;
  }

  ul {
    list-style: disc;
  }

  ul, ol {
    font-size: $fontSize-sm;
    margin-top: 10px;
    padding-left: 25px;

    + p {
      padding-top: 10px;
    }
  }

  li p {
    padding-bottom: 5px;
  }

  > *:empty {
    display: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    margin-top: 16px;
    overflow-x: auto;
    width: 100%;

    td {
      font-size: $fontSize-sm;
      min-width: 160px;
      padding: 16px;
      text-align: left;
    }

    th {
      background-color: $greyLightestPlus;
      min-width: 160px;
      padding: $fontSize-m;
      text-align: left;
    }

    tr {
      border-bottom: 1px solid $greyLightest;

      &:last-of-type {
        border: 0;
      }
    }

    p {
      padding-bottom: 0;

      & + p {
        margin-top: 15px;
      }
    }
  }
}

.CmsSmallPrint,
.CmsSmallPrint p {
  color: $greyLighter;
  font-size: $fontSize-xs !important;
  line-height: 1.3 !important;
  margin-bottom: 0 !important;
  padding-top: 5px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .CmsRichtext {

    h2 {
      font-size: $fontSize-lm;
    }

    h3 {
      font-size: $fontSize-m;
    }
  }
}
