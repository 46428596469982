/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CtaBanner {
  background: $purpleDarker;
  color: $white;
  padding: 6px 40px;
  position: relative;
  text-align: center;

  :global {

    .underline {
      text-decoration: underline;
    }

    .margin-left {
      padding-left: 48px;
    }

    .margin-right {
      padding-right: 48px;
    }

    .margin-both {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}

.CtaBannerContent {
  font-size: $fontSize-m;
  line-height: 22px;

  b {
    font-weight: $font-weight-l;
  }
}

.CtaBannerClose {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .5s $defaultBezier;

  &:hover {
    transform: translateY(-50%) rotate(-180deg);
  }

  &:before {
    color: $white;
  }
}

.Pointer {
  cursor: pointer;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .CtaBanner {
    padding: 5px 25px;
  }

  .CtaBannerContent {
    font-size: $fontSize-sm;
    line-height: 14px;
  }
}
