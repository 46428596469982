/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ForgotPasswordCheckout {

  form {
    display: block;
  }
}

.SignInFormForgotPassword {
  margin-bottom: 22px;
  margin-top: 4px;
  text-align: right;
}

.SignInFormOther {
  display: grid;
  justify-content: center;
  margin-top: 10px;

  button {
    width: 240px;
  }
}

.SignInFormOtherLabel {
  margin-bottom: 10px;
}

.SignInFormButton {
  margin: 0 auto;
  min-width: 150px;
  width: auto;
}

.ForgotPasswordButtonWrapper {
  align-items: center;
  display: flex;
  flex-flow: column;

  button {
    margin: 0 0 30px;
    max-width: 100%;
    width: 340px;
  }
}
