/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ProductInfoListing {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.ProductInfo {
    vertical-align: top;
  }
}

.ProductInfo {
  border: 1px solid $greyLightestPlus;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
  max-width: none;
  outline: none;
  padding: 16px 16px 24px;
  position: relative;
  transition: all .3s $defaultBezier;
  width: 49%;
}

.ProductInfoTitle {

  span {
    font-size: $fontSize-sm;
    font-weight: $font-weight-s;
    line-height: 22px;
  }

  h4 {
    font-size: $fontSize-m;
    font-weight: $font-weight-m;
    margin-top: 8px;
  }
}

.ProductInfoImage {
  height: 160px;
  margin: 16px auto;
  text-align: center;
  width: 160px;
}

.ProductInfoBottom {
  max-width: 250px;
}

.ProductInfoDescription {
  color: $greyDarkest;
  line-height: 20px;
  margin-bottom: 20px;
  margin-top: 20px;

  h2 {
    font-size: $fontSize-l;
    font-weight: $font-weight-m;
  }

  strong {
    font-weight: $font-weight-l;
  }

  ul {
    margin-top: 16px;
  }

  li {
    font-size: $fontSize-sm;
    line-height: 22px;
    margin-bottom: 9px;
    padding-left: 23px;
    position: relative;

    &:before {
      color: $asamGreenDarker;
      font-size: $fontSize-Icon12;
      left: 4px;
      position: absolute;
      top: 6px;
    }
  }
}

@media screen and ( $mobile-media ) {

  .ProductInfo {
    width: 100%;
  }
}
