/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.AccountMenuHolder {

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 10px 0;

    &[ data-inspect = 'customer-logout' ] {
      border-top: 1px solid $greyLightest;
      margin-bottom: 0;
      margin-top: 20px;
      padding-top: 20px;

      a {
        font-weight: $font-weight-l;
        position: relative;

        &:after {
          color: $greyDarkest;
          font-size: $fontSize-Icon15;
          margin-left: 6px;
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%);
        }

        &:hover {

          &:after {
            color: $purpleDarker;
          }
        }
      }
    }
  }

  a {
    color: $greyDarker;
    display: block;
    font-size: 14px;
    line-height: 22px;

    &:hover {
      color: $purpleDarker;
    }
  }

  :global {

    .active {
      color: $purple;
    }
  }
}

.AccountMenuTitle {
  font-size: 16px;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin: 0 0 16px;
}
