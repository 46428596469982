/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.VideoHeight {
  position: relative;

  .VideoOverlay {
    opacity: 1;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: absolute;
    transition: padding-bottom 0.5s linear;
    transition-delay: 0.3s;
    width: 100%;

    .VideoLogoWrapper {
      left: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.2s linear;
      z-index: 2;

      &:before {
        font-size: $fontSize-Icon78;
      }
    }

    img {
      left: 0;
      opacity: 1;
      position: absolute;
      transition: all 0.2s linear;
      z-index: 1;
    }
  }

  &.Active {

    .VideoOverlay {
      padding-bottom: 0;

      .VideoLogoWrapper,
      img {
        opacity: 0;
      }
    }
  }
}

.VideoHeightYouTube {
  padding-bottom: 56.25%;
}

.FBVideoHeight {
  height: 0;
  padding-bottom: 56.25%;
}

iframe.CmsVideo {
  height: 99%;
  left: 0;
  position: absolute;
  width: 100%;
}

.VideoHolder {
  position: relative;

  video {
    display: block;
    height: auto;
    width: 100%;
  }
}
