/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsPlaceholder {
  background-color: $white;
  border: solid 1px $greyLightest;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  width: 100%;
  z-index: 2;
}

.CmsPlaceholderHeadLine {
  color: $greyDarkest;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  text-align: left;
}

.CmsPlaceholderSubLine {
  margin-top: 10px;

  a {
    text-decoration: underline;
  }
}

.CmsPlaceholderIcons {
  text-align: center;

  img {
    max-width: 450px;
    width: 100%;
  }
}

.CmsPlaceholderButton {
  background-color: $purpleDarkest;
  border-radius: 24px;
  color: $white;
  cursor: pointer;
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  height: 40px;
  margin: 10px auto 0px;
  padding: 8px 16px;
  text-align: center;
  width: 295px;
}
