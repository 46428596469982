/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.FormFieldWithTooltip {
  position: relative;
}

.FormFieldWarning {
  color: $orangeWarning;
  font-size: 12px;
  justify-self: end;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
  padding-right: 16px;
  pointer-events: none;
}
