/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CloseCaptureUp {
  display: block;
  height: 50px;
  position: absolute;
  top: -25px;
  width: 100%;
  z-index: 6;
}

.CloseCaptureRight {
  flex: 0 0 10px;
  width: 10px;
}

.CloseCaptureLeft {
  height: 300px;
  margin-left: -100px;
  position: absolute;
  top: -55px;
  width: 100px;
}

.ScrollMenu {
  max-width: 100%;
  position: relative;
  z-index: 4;
}

.ScrollMenuItem {
  cursor: pointer;
  user-select: none;
}

.ScrollMenuArrow {
  cursor: pointer;
}

.ScrollMenuArrowPrev,
.ScrollMenuArrowNext {
  cursor: pointer;
  height: 80%;
  position: absolute;
  top: 10%;
  width: 70px;
  z-index: 3;

  &:before {
    @extend .icomoon-font !optional;
    color: $grey;
    display: block;
    font-size: 21px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  svg {
    height: 100%;
    width: 12px;

    path {
      stroke: $grey;
    }
  }
}

.ScrollMenuArrowPrev {
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 15%, $white 85%);
  left: 0;

  &:before {
    content: $iconChevronThinLeft;
    left: -4px;
  }
}

.ScrollMenuArrowNext {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 15%, $white 85%);
  right: 0;

  &:before {
    content: $iconChevronThinRight;
    right: -4px;
  }

  svg {
    float: right;
  }
}

.ScrollMenuArrowDisabled {
  display: none;
}

.Nav {
  border-bottom: 1px solid $greyLightest;
  border-top: 1px solid $greyLightest;
  position: relative;
}

.Menu {
  color: $white;
  display: flex;
  font-size: $fontSize-m;
  line-height: 22px;
  padding-right: 150px;

  :global {

    .menu-wrapper--inner {
      line-height: 1;
    }
  }
}

.MenuItem {
  white-space: nowrap;
  width: auto !important;

  &:hover {

    .MenuLink {

      &:before {
        font-weight: $font-weight-l;
      }

      &:after {
        width: 100%;
      }
    }
  }

  &.Active {

    .MenuArrow {
      opacity: 1;
      visibility: visible;
    }
  }
}

.MenuItemHighlighted {

  a {

    &:after {
      background: $purple;
      border-radius: 25%;
      content: '';
      height: 14px;
      left: 0;
      opacity: .2;
      position: absolute;
      top: 50%;
      transform: rotate(2deg) translateY(-50%);
      width: 100%;
    }
  }
}

.Active {

  > div {
    opacity: 1;
    visibility: visible;
    z-index: 5;
  }
}

.MenuLink {
  padding: 24px 12px 26px;
  position: relative;

  &:before {
    color: $greyDarker;
    content: attr(data-label);
    font-size: $fontSize-m;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s $defaultBezier;
    width: 100%;
  }

  &:after {
    background: $asamGreen;
    border-radius: 1px;
    bottom: 15px;
    content: '';
    height: 2px;
    left: 0;
    pointer-events: none;
    position: absolute;
    transition: all .3s $defaultBezier;
    width: 0;
  }

  &.MenuLinkBold {

    &:before {
      font-weight: $font-weight-l;
    }
  }
}

@keyframes pulse {

  from {
    box-shadow: 0 0 0 0 rgba(201, 41, 105, 0.2);
  }

  to {
    box-shadow: 0 0 0 8px rgba(201, 41, 105, 0.2);
  }
}

.live {
  margin-left: 20px;

  &:after {
    animation-direction: alternate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    animation-timing-function: ease-in-out;
    background: $purple;
    border-radius: 50%;
    height: 12px;
    left: -12px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    width: 12px !important;
    z-index: 1;
  }
}

.MenuArrow {
  height: 15px;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 57px;
  transform: translateX(-50%);
  visibility: hidden;
  width: 16px;
  z-index: 4;

  &:after {
    box-shadow: 0 0 8px rgba(33, 34, 36, 0.74);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-62deg);
    width: 11px;
    z-index: 1;
  }

  &:before {
    box-shadow: 0 0 8px rgba(33, 34, 36, 0.74);
    content: '';
    height: 1px;
    position: absolute;
    right: 0;
    top: 7px;
    transform: rotate(62deg);
    width: 11px;
    z-index: 1;
  }
}

.MenuArrowBg {
  border-color: $transparent $transparent $white;
  border-style: solid;
  border-width: 0 8px 15px;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 2;
}

.MenuWrapper {
  flex: 0 0 910px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.MenuScroll {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 100%;
  position: relative;
  transition: transform .2s ease-in-out;
  width: 100%;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Nav {
    display: none;
  }
}
