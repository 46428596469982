/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.NotFoundTitleInfo {
  background-color: $greyLightestPlus;
  border: 1px solid $greyLightestPlus;
  border-radius: 8px;
  font-size: $fontSize-sm;
  font-weight: $font-weight-s;
  line-height: normal;
  margin: 24px 0;
  padding: 24px;
}

.ProductSliderWrapper {
  padding-top: 35px;
}

@media screen and ( $tablet-media ) {

  .ProductSliderWrapper {
    border-bottom: 0;
    padding-top: 0;
  }
}
