/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.LoadingBar {
  background: $grey;
  height: 2px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 100;

  &.Visible {
    visibility: visible;
  }
}
