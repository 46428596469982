/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.MultiselectHeader {
  position: relative;
  z-index: 1;
}

.MultiselectArrow {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  box-sizing: border-box;
  height: 24px;
  position: absolute;
  right: 24px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s $defaultBezier;
  width: 24px;

  &:after {
    font-size: $fontSize-Icon25;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .MultiselectArrowIcon {
    height: 7px;
    width: 12px;

    &:after {
      color: $purpleDarker;
    }
  }

  .MultiselectMinusIcon, .MultiselectPlusIcon {

    &:after {
      color: $purpleDarker;
      font-size: $fontSize-Icon16;
    }
  }
}

.MultiselectContent {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-20px);
  transition: all .3s $defaultBezier;

  &.BlockContentEvents {

    > * {
      pointer-events: none;
    }
  }

  > * {
    pointer-events: initial;
  }
}

.MultiselectGroup {

  &.Active {

    .MultiselectArrow {
      transform: translateY(-50%) rotate(-180deg);
    }

    .MultiselectContent {
      max-height: 20000px;
      opacity: 1;
      pointer-events: initial;
      transform: translateY(0);

      &.BlockContentEvents {

        > * {
          pointer-events: initial;
        }
      }
    }
  }

  .MultiselectGroupContent > div {
    display: block !important;
    height: auto !important;
  }

  &:not(.OnlyMobile) {

    .MultiselectGroupTitle {
      background: $white;
      border-top: 1px solid $greyLightestPlus;
      font-size: $fontSize-sm;
      font-weight: $font-weight-l;
      margin-bottom: 0;
      padding: 12px 35px;

      > div {
        margin-bottom: 0;
      }

      &.MultiselectTitleSecondary {
        border-color: $greyLightest;
        font-size: $fontSize-m;
      }
    }

    .MultiselectGroupContent {
      padding: 20px 35px;
    }
  }

  .MultiselectGroupContent {

    section > div {
      padding-left: 0;
      padding-right: 0;
    }

    > section:first-of-type {

      > div > div {
        padding-top: 0;
      }
    }
  }
}

.OnlyMobile {

  .MultiselectArrow {
    display: none;
  }

  .MultiselectContent {
    max-height: none;
    opacity: 1;
    transform: translateY(0);
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .OnlyMobile {

    .MultiselectArrow {
      display: block;
    }

    .MultiselectContent {
      max-height: 0;
      opacity: 0;
      transform: translateY(-20px);
      transition: all .3s $defaultBezier;
    }

    .MultiselectGroupTitle {
      background: $white;
      border-top: 1px solid $greyLightestPlus;
      font-size: $fontSize-sm;
      font-weight: $font-weight-l;
      margin-bottom: 0;
      padding: 12px 35px;

      > div {
        margin-bottom: 0;
      }
    }

    .MultiselectGroupContent {
      padding: 20px 35px;
    }
  }

  .MultiselectGroup {

    &.RemovePaddingOnMobile {

      .MultiselectGroupTitle {
        padding-left: 0;
        padding-right: 0;
      }

      .MultiselectArrow {
        right: 0;
      }

      .MultiselectGroupContent {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

@media screen and ( $mobile-media ) {

  .MultiselectArrow {
    right: 16px;
  }

  .MultiselectGroup {

    &:not(.OnlyMobile) {

      .MultiselectGroupTitle {
        padding: 12px 29px;

        &.MultiselectTitleSecondary {
          font-size: $fontSize-s;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      }
    }

    &.RemovePaddingOnMobile {

      .MultiselectGroupTitle {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
