/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

header + .ErrorBoundaryContainer .ErrorBoundaryLogo svg {
  display: none;
}

.ErrorBoundaryContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: $width;
  padding-left: $paddingLeft;
  padding-right: $paddingRight;
  width: 100%;
}

.ErrorBoundaryWrapper {
  margin: 0 auto 150px;

  .ErrorBoundaryLogo {
    margin: 150px auto 100px;
    max-width: 250px;
  }

  .ErrorBoundaryContent {
    background: $white;
    border: 1px solid $greyLightest;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    max-width: 800px;
    padding: 48px;

    h2 {
      font-family: $fontFamily;
      font-size: $fontSize-xxl;
      font-weight: 100;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      margin-bottom: 5px;
    }

    p {
      color: $black;
      font-family: $fontFamily;
      font-size: $fontSize-m;
      line-height: 1.5;
    }

    .ErrorBoundaryButton {
      margin-top: 25px;
      max-width: 250px;
      position: relative;

      button:first-child {
        display: block;
      }

      button:last-child {
        display: none;
      }

      &.ErrorBoundarySwitch {

        button:first-child {
          display: none;
        }

        button:last-child {
          display: block;
        }
      }
    }

    .ErrorBoundaryLoader {
      align-items: center;
      display: flex;
      justify-content: center;

      svg {
        animation: loader 0.8s linear infinite;
        display: block;
        height: 45px;
        width: 45px;
      }
    }
  }
}

@media screen and ( $mobile-media ) {

  .ErrorBoundaryWrapper {
    margin-bottom: 50px;

    .ErrorBoundaryLogo {
      margin: 50px auto 25px;
    }

    .ErrorBoundaryContent {
      grid-template-columns: 1fr;

      .ErrorBoundaryLoader {
        margin-top: 15px;
      }
    }
  }
}

@keyframes loader {

  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
