/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Gallery {
  margin-left: auto;
  margin-right: auto;
  max-width: 580px;

  :global {

    .image-gallery-image {
      align-items: center;
      display: flex;
      min-height: 100%;
    }

    .video-holder {

      &:before, &:after {
        display: none;
      }

      video {
        display: block;
        height: auto;
        object-fit: cover;
        position: absolute;
        width: 100%;
      }
    }
  }
}

.ShowLabel {

  > div:first-of-type {
    opacity: 1;
    transition: all .3s $defaultBezier;
  }
}

.HideLabel {

  > div:first-of-type {
    opacity: 0;
  }
}

.BadgeWrapper {
  display: flex;
  left: 0;
  margin-top: calc(100% - 50px);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.YoutubePlayButton {
  background-color: $transparent !important;
  height: auto !important;
  left: 0;
  margin: auto;
  padding: 0;
  position: absolute;
  right: 0;

  &:hover,
  &:hover:enabled {
    background: transparent;
  }
}

.GalleryVideo {

  iframe {
    height: 100%;
    width: 100%;
  }

  video {
    display: block;
    height: auto;
    width: 100%;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Gallery {
    max-width: 100%;
  }

  .BadgeWrapper {
    margin-top: calc(100% - 40px);
  }
}

@media screen and ( $custom-620-media ) {

  .Gallery {

    iframe {
      height: 100%;
    }
  }
}

@media screen and ( $mobile-media ) {

  .Gallery {
    max-width: 400px;

    :global {

      /* prevent glitch on right (and left) sided slide */

      .image-gallery-slide {

        &.right {
          margin-left: 1%;
        }

        &.left {
          margin-right: 1%;
        }
      }

      .image-gallery-image {
        margin-top: 8px;
        min-height: 100%;
      }
    }
  }
}
