/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.BasePriceHolder {

  &:not(.NoWrapped) {

    &:before {
      content: ' (';
    }

    &:after {
      content: ')';
    }
  }
}
