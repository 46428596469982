/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.NumberInput {
  border: 1px solid $greyLightest;
  border-radius: 8px;
  display: flex;
  height: 32px;
  width: 84px;

  button {
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    display: block;
    outline: none;
    width: 24px;

    &:before {
      color: $purpleDarker;
      font-size: $fontSize-Icon18;
      line-height: 30px;
    }
  }

  :global {

    .locked {

      &:before {
        color: $greyLightest;
      }
    }
  }

  input {
    background: $greyLightestPlus;
    border: 0;
    border-left: 1px solid $greyLightest;
    border-right: 1px solid $greyLightest;
    display: block;
    font-family: $fontFamily;
    font-size: $fontSize-l;
    font-weight: $font-weight-l;
    line-height: 30px;
    max-block-size: -webkit-fill-available;
    text-align: center;
    text-size-adjust: unset;
    width: 36px;

    &:focus {
      outline: none;
    }
  }
}
