/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Details {

  :global {

    h2 {
      font-size: $fontSize-xl;
      font-weight: $font-weight-m;
      margin-bottom: 12px;
    }

    h3 {
      font-size: $fontSize-l;
      font-weight: $font-weight-m;
      margin-bottom: 4px;
    }

    h4 {
      margin-bottom: 10px;
    }

    br {
      display: none;
    }

    p {
      font-size: $fontSize-sm;
      line-height: 22px;
      margin-bottom: 5px;
    }

    img {
      width: 100%;
    }

    li {
      font-size: $fontSize-sm;
      line-height: 22px;
    }

    strong {
      font-weight: $font-weight-l;
    }

    .panel-left {
      clear: left;
      float: left;
      width: 49%;
    }

    .panel-right {
      float: right;
      width: 40.5%;
    }

    .panel-desc {
      color: $greyDarkest;
      overflow: hidden;

      > .panel-left {

        ~ .panel-left {

          h3 {
            margin-top: 20px;
          }
        }
      }
    }

    .expandable-block {
      overflow: hidden;
      width: 100%;

      h3 {
        margin-top: 20px;
      }
    }
  }

  .ExpandableContent {
    max-height: 0;
    overflow: hidden;
    transition: 0.7s;

    > div {
      padding-left: 0;
      padding-right: 0;
    }

    &.Active {
      max-height: 4000px;
    }
  }

  .ButtonWrapper {
    display: block;
    width: 49%;

    button {

      span:first-of-type {
        display: none;
      }

      span:last-of-type {
        display: block;
      }
    }

    &.Active {

      button {

        span:first-of-type {
          display: block;
        }

        span:last-of-type {
          display: none;
        }
      }
    }
  }

  .IngredientsNote {
    font-size: $fontSize-xs;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .Details {
    margin-bottom: 50px;
    margin-top: 50px;

    :global {

      .panel-left,
      .panel-right {
        width: 100%;
      }
    }

    .ButtonWrapper {
      width: auto;
    }
  }
}
