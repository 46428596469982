
/*------------------------------------*\
  #Layout
\*------------------------------------*/
.PageLoaderContainer {
  min-height: 90vh;
  position: relative;
  z-index: -1;

  &:after {
    content: '';
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translate(-100%, 0);
    animation: glow 2s forwards infinite ease-in-out;
  }
}

.PageLoader {
  animation: fadeIn 0.2s linear;
  padding-top: 50px;

  > img,
  > svg {
    width: 100%;
    height: 100%;
    max-width: unset;
  }
}

.plpLoaderDesktop {
  background: url("../../assets/images/svgtopng/plpSkeletonDesktop.png") no-repeat top 50px center;
  background-size: cover;
  min-height: 1170px;
  position: relative;
}

.plpLoaderTablet {
  background: url("../../assets/images/svgtopng/plpSkeletonTablet.png") no-repeat top 30px center;
  background-size: cover;
  min-height: 780px;
  position: relative;
}

.plpLoaderMobile {
  background: url("../../assets/images/svgtopng/plpSkeletonMobile.png") no-repeat top 10px center;
  background-size: cover;
  min-height: 707px;
  position: relative;
}

.pdpLoaderDesktop {
  background: url("../../assets/images/svgtopng/pdpSkeletonDesktop.png") no-repeat top 50px center;
  background-size: cover;
  min-height: 726px;
  position: relative;
}

.pdpLoaderMobile {
  background: url("../../assets/images/svgtopng/pdpSkeletonMobile.png") no-repeat top 10px center;
  background-size: cover;
  min-height: 500px;
  position: relative;
}

.PageLoaderDesktop {
  background: url("../../assets/images/svgtopng/cmsSkeletonDesktop.png") no-repeat top 50px center;
  background-size: contain;
  min-height: 726px;
  position: relative;
}

.PageLoaderMobile {
  background: url("../../assets/images/svgtopng/cmsSkeletonMobile.png") no-repeat top 10px center;
  background-size: contain;
  min-height: 500px;
  position: relative;
}

.RelativeContainer {
  position: relative;
}

.ClearFloat {
  clear: both;
}

.Hide {
  display: none;
}

.ShowTablet, .ShowMobile {
  display: none !important;
}

.ShowInlineTablet, .ShowInlineMobile {
  display: none !important;
}

.MainContent {
  min-height: 1000px;
}

.StopBgScrolling {
  overflow: hidden;
}

.TextCenter {
  text-align: center !important;
}

.StatusActive {
  color: $asamGreenDarker;
}

.StatusCanceled {
  color: $redError;
}

.StatusInactive {
  color: $orangeDarker;
}

.NoticeWrapper {
  background: $orangeInfoAlpha;
  border: 1px solid $orangeInfo;
  border-radius: 8px;
  margin: 32px 0;
  padding: 16px 48px 16px 16px;
  position: relative;

  &:after {
    color: $orangeInfo;
    display: block;
    font-size: 21px;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}

.NoticeWrapperContent {
  font-size: $fontSize-sm;
  margin-bottom: 0 !important;

  a {
    text-decoration: underline;
  }
}

.PlayButtonIcon {
  background: transparent;
  border: 4px solid white;
  border-radius: 50%;
  display: block;
  height: 73px;
  margin: auto;
  position: relative;
  width: 73px;
  transition: transform 0.2s linear;

  &:before{
    border: solid transparent;
    border-left-color: $white;
    border-radius: 2px;
    border-width: 16px 0 16px 24px;
    bottom: 50%;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    transform: translate(-8px, 50%);
    width: 0;
  }

  &:hover {
    transform: scale(1.2);
  }
}

@media screen and ( $tablet-media ) {

  .HideTablet {
    display: none !important;
  }

  .ShowTablet {
    display: block !important;
  }

  .ShowInlineTablet {
    display: inline !important;
  }
}

@media screen and ( $mobile-media ) {
  .PageLoader {
    padding-top: 10px;
  }

  .HideMobile {
    display: none !important;
  }

  .ShowMobile {
    display: block !important;
  }

  .ShowInlineMobile {
    display: inline !important;
  }

  .mobileAppView {

    .themed-app-layout {
      padding-bottom: 90px;
    }
  }
}

/*------------------------------------*\
  #Animations
\*------------------------------------*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes glow {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}
