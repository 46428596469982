/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.MenuDrawer {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(33, 34, 36, 0.24);
  color: $greyDarkest;
  display: flex;
  min-height: 260px;
  opacity: 0;
  visibility: hidden;
  z-index: 3;

  &.MenuDrawerVisible {
    opacity: 1;
    visibility: visible;
  }

  .MenuDrawerList {
    color: $greyDarkest;
  }
}

.MenuDrawerSpecial {
  background: $greyLightestPlus;
  border-radius: 0 8px 8px 0;
  padding: 30px 12px;
  width: 23.6%;
}

.MenuDrawerList {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 30px 12px;
}

.MenuDrawerClose {
  background: $white;
  border: none;
  border-radius: 10px;
  bottom: -20px;
  padding: 2px 10px;
  position: absolute;
  right: 50%;
  transform: translateX(25%);

  &:before {
    color: $purpleDarker;
    font-size: $fontSize-Icon48;
  }
}

.MenuDrawerColumn {
  padding-right: 12px;
}

.MenuDrawerColumnGroup {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    margin-bottom: 19px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.MenuTitle {
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-bottom: 10px;
  margin-left: 12px;
  text-transform: uppercase;
}

.MenuDrawerImage {
  margin-left: 12px;

  a {
    display: block;
    transition: all .3s $defaultBezier;

    &:hover {
      opacity: 0.9;
    }
  }

  img {
    display: block;
    max-width: 202px;
  }
}

.MenuDrawerLinks {

  li {
    border-radius: 16px;
    cursor: pointer;
    transition: all .2s $defaultBezier;

    &:hover {
      background: $purpleDarker;
      color: $white;
    }
  }

  a {
    display: block;
    padding: 5px 12px;
  }

  &.Secondary {

    li {
      margin-bottom: 12px;
      position: relative;

      &:hover {

        .Sale {

          circle {
            fill: transparent;
          }
        }

        .New {

          circle {
            stroke: $white;
          }

          path {
            fill: $white;
          }
        }

        .Star {

          path {
            fill: $white;
            stroke: $white;
          }
        }
      }

      &.NoImage {

        a {
          font-weight: $font-weight-m;
          text-transform: initial;

          &:after {
            background: $greyDarkest;
            content: '';
            height: 4px;
            left: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
          }
        }

        &:hover {

          a {

            &:after {
              background: $white;
            }
          }
        }
      }
    }

    a {
      font-weight: $font-weight-l;
      line-height: 22px;
      padding-left: 36px;
      text-transform: uppercase;
    }

    svg {
      height: 24px;
      left: 4px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    img {
      left: 4px;
      max-width: 24px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
