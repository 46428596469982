/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Header {
  padding: 34px 0;
  position: relative;
  z-index: 10;
}

.HeaderHolder {
  position: relative;
}

.HeaderSpacer {
  height: 74px;
  position: relative;
}

.HeaderStuck {
  background: $white;
  box-shadow: 0 1px 4px rgba(33, 34, 36, 0.24);
  left: 0;
  position: fixed;
  top: 0;
  transition: .12s;
  width: 100%;
  z-index: 8;
}

.HeaderLogo {
  margin-left: auto;
  margin-right: auto;
  width: 240px;

  :global {

    a {
      display: block;
      line-height: 1;
    }

    img {
      display: block;
      height: auto;
      max-height: 60px;
      width: 100%;
    }
  }
}

.HeaderActions {
  margin-top: -25px;
  position: absolute;
  right: 0;
  top: 50%;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  header {
    box-shadow: 0 1px 4px rgba(33, 34, 36, 0.24);
  }

  .Header {
    padding: 11px 0 18px;
  }

  .HeaderLogo {
    width: 173px;
  }

  .HeaderActions {
    height: 47px;
    margin-top: -18px;
    right: 22px;
    width: 76px;
  }
}

@media screen and ( $mobile-media ) {

  .Header {
    padding: 16px 0;
  }

  .HeaderLogo {
    min-height: 41px;
    width: 165px;
  }

  .HeaderActions {
    right: 7px;
  }
}

@media screen and ( $custom-370-media ) {

  .HeaderLogo {
    transform: translateX(-20px);

    svg g {
      display: none;
    }
  }
}
