/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Tooltip {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &.TooltipRelative {
    position: relative;
    top: 0;
    transform: none;
  }
}

.TooltipTrigger {
  cursor: pointer;

  &:before {
    font-size: $fontSize-Icon15;
  }

  svg {
    display: block;
    height: 16px;
    width: 16px;
  }
}

.TooltipLabel {
  cursor: pointer;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  position: relative;

  span {
    padding-right: 18px;
    position: relative;
  }
}

.TooltipBox {
  display: block;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  margin: 0;
  position: absolute;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: nowrap;
  word-break: normal;
  word-spacing: normal;
  word-wrap: break-word;
  z-index: 1070;
}

.TooltipContent {
  background: $white;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(33, 34, 36, 0.4);
  box-sizing: border-box;
  font-family: $fontFamily;
  max-width: 310px;
  padding: 16px;
  position: relative;

  :global {
    color: $greyDarkest;
    text-align: left;

    .title {
      font-size: $fontSize-sm;
      font-weight: $font-weight-l;
      line-height: 22px;
    }

    .list {
      margin-top: 8px;
    }

    .listItem {
      font-size: $fontSize-sm;
      line-height: 22px;
      margin-bottom: 6px;
      padding-left: 19px;
      position: relative;

      svg {
        height: 10px;
        left: 3px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 11px;
      }
    }
  }
}

.TooltipClose {
  cursor: pointer;
  height: 19px;
  line-height: 1;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: all .3s $defaultBezier;
  width: 19px;

  &:hover {
    transform: rotate(90deg);
  }

  &:before {
    color: $purpleDarker;
    font-size: $fontSize-l;
    pointer-events: none;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .TooltipToLeft {

    .TooltipContent {
      left: 0;
      transform: translateX(0);
    }

    .TooltipArrow {
      left: 10px;
    }
  }

  .TooltipToRight {

    .TooltipContent {
      left: auto;
      right: 0;
      transform: translateX(0);
    }

    .TooltipArrow {
      left: auto;
      right: 10px;
    }
  }
}
