/*------------------------------------*\
  #Reset
\*------------------------------------*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  overflow: hidden;
}

header + .themed-loader {
  min-height: 90vh;
  height: auto
}

header + .SpaceToTop {
  margin-top: 90vh;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-weight: 600;
}

::-moz-focus-inner {
  border: 0;
}

@media print {
  body {
    overflow: visible;
  }
}