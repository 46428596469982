/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.MobileMenuIcon {
  display: none;
  left: 17px;
  position: absolute;
  top: 21px;

  span {
    background: $purpleDarker;
    border-radius: 2px;
    display: block;
    height: 2px;
    margin-bottom: 10px;
    width: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .MobileMenuIcon {
    display: block;
  }
}

@media screen and ( $mobile-media ) {

  .MobileMenuIcon {
    align-content: center;
    align-items: flex-start;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    top: 16px;
    width: 50px;

    span {
      margin-bottom: 7px;
    }
  }
}
