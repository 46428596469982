/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Price {
  display: inline-block;
  font-size: 28px;
  font-weight: $font-weight-l;
  line-height: 20px;
}

.PriceUvp {
  color: $greyLighter;
  font-size: $fontSize-sm;
  margin-right: 4px;
}

.SpecialMode {

  .Price {
    color: $greyLighter;
    display: inline-block;
    font-size: $fontSize-m;
    font-weight: $font-weight-m;
    line-height: 20px;
    text-decoration: line-through;
  }

  .SpecialPrice {
    color: $purple;
    font-size: 28px;
    font-weight: $font-weight-l;
    line-height: 20px;
  }
}

.Secondary {

  .Price {
    font-size: 22px;
  }

  &.SpecialMode {

    .Price {
      font-size: 14px;
    }

    .SpecialPrice {
      font-size: 22px;
    }
  }
}

.Inherit {
  display: inline;

  .Price {
    font-size: inherit;
    font-weight: inherit;
  }
}
