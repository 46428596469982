/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Loader {
  background: rgba(255, 255, 255, 0.9);
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all .3s $defaultBezier;
  visibility: hidden;
  width: 100%;
  z-index: 55;

  &:before {

    :global {
      animation: spin .4s infinite linear;
    }
    border: 4px solid $purpleDarker;
    border-color: $purpleDarker transparent transparent $purpleDarker;
    border-radius: 50%;
    content: '';
    display: block;
    height: 32px;
    left: 50%;
    margin: -16px 0 0 -16px;
    position: absolute;
    top: 50%;
    width: 32px;
  }

  &.LoaderVisible {
    opacity: 1;
    visibility: visible;
  }
}
