/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Badge {
  color: $white;
  line-height: 62px;
  text-align: center;
  text-transform: uppercase;
}

.BadgeText {
  padding: 0 16px;
  position: relative;
  z-index: 2;
}

.BadgeGrey {

  &:after, &:before {
    background: $greyDarker;
  }
}

.BadgeOrange {

  &:after, &:before {
    background: $orangeDarker;
  }
}

.BadgePurple {

  &:after, &:before {
    background: $purpleDarker;
  }
}

.BadgeGreen {

  &:after, &:before {
    background: $asamGreenDarker;
  }
}

.BadgeSkin {

  &:after, &:before {
    background: $skinDarkestPlus;
  }
}

.BadgeBlack {

  &:after, &:before {
    background: $black;
  }
}

.BadgeDarkGreen {

  &:after, &:before {
    background: $darkGreen;
  }
}

.BadgeSmall {
  border-radius: 2px;
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;
  margin-right: auto;
  padding: 2px 0;
  position: relative;

  &:after {
    border-radius: 2px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:before {
    border-radius: 2px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.BadgeLarge {
  border-radius: 2px;
  font-size: $fontSize-s;
  font-weight: $font-weight-l;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 163px;
  padding: 2px 0;
  position: relative;

  &:after {
    border-radius: 2px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:before {
    border-radius: 2px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
