/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.InputWrapper {
  position: relative;
}

.InputWrapperNoShow {
  display: none;
}

.Input {

  &.WithoutStyles {
    background: none;
    border: 0;

    &:hover, &:active, &.InputHasValue {
      background: none;
    }

    &:focus {
      background: none;
      box-shadow: none;
    }

    &.InputHasError {
      background: none;
      border: none;
    }

    + .SuccessIcon {
      display: none;
    }
  }
  appearance: none;
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  color: $greyDarkest;
  display: block;
  font-family: $fontFamily;
  font-size: $fontSize-m;
  height: 40px;
  line-height: 22px;
  margin-bottom: 24px;
  outline: none;
  padding: 9px 14px;
  transition: all .4s $defaultBezier;
  width: 100%;

  &::placeholder {
    color: $greyLighter;
    font-style: italic;
    opacity: 1;
  }

  &::-ms-clear {
    display: none;
  }

  &:-ms-input-placeholder {
    color: $greyLighter;
    font-style: italic;
  }

  &::-ms-input-placeholder {
    color: $greyLighter;
    font-style: italic;
  }

  &:disabled {
    border-color: $greyLightest;
    opacity: 0.6;
  }

  &:hover {
    background: $greyLightestPlus;
    border-color: $greyLighter;
  }

  &:active, &.InputHasValue {
    background: $white;
    border-color: $greyLighter;
  }

  &:focus {
    background: $white;
    border-color: $greyLighter;
    box-shadow: 0 0 0 1px $greyLighter;
  }

  &.InputHasError {
    background: $redErrorAlpha;
    border-color: $redError;
  }

  &.InputHasSuccess {
    border-color: $asamGreenDarkest;

    &:disabled {
      border-color: $greyLightest;
    }
  }

  &.InputHasWarning {
    border-color: $orangeWarning;
    margin-bottom: 6px;

    &:disabled {
      border-color: $greyLightest;
    }
  }

  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }

  &:focus, &.InputHasValue, &.InputHasError, &:active {

    & + .Label {
      left: 14px;
      opacity: 1;
    }
  }

  &.InputNoMargin {
    margin-bottom: 0;
  }

  &.TextArea {
    color: $greyDarkest;
    font-family: $fontFamily;
    font-size: $fontSize-m;
    height: 210px;
    resize: none;
  }

  &.InputTextCenter {
    text-align: center;
  }

  &.InputWithVisibleMessage {

    &.InputHasError {
      margin-bottom: 6px;
    }
  }
}

.InputSecondary {
  background: $white;
}

.ErrorIcon {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);

  &:before {
    color: $redError;
    font-size: $fontSize-Icon15;
  }
}

.SuccessIcon {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);

  &:before {
    color: $asamGreenDarkest;
    font-size: $fontSize-Icon12;
  }
}

.Label {
  color: $grey;
  font-size: $fontSize-xs;
  left: 10px;
  line-height: 12px;
  opacity: 0.01;
  padding: 0 3px;
  pointer-events: none;
  position: absolute;
  top: -7px;
  transition: all .3s $defaultBezier;
  z-index: 2;

  &:after {
    background: $white;
    bottom: 4px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

.InputTooltip {
  cursor: pointer;
  margin-top: -8px;
  position: absolute;
  right: 14px;
  top: 50%;
}

.InputIcon {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
}
