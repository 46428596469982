/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Checkbox {
  display: inline-block;
  position: relative;

  input {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    visibility: visible;

    &:checked {

      + label .CheckboxStyled {

        &:before {
          opacity: 1;
        }
      }
    }

    &:focus {

      + label .CheckboxStyled {
        border-color: $purpleDarker;
        border-width: 2px;
      }
    }

    &[ disabled ] {

      + label .CheckboxStyled {
        background: $greyLightestPlus;
        border-color: $greyLighterPlus;
        border-width: 1px;

        &:before {
          color: $greyLighterPlus;
        }

        + .CheckboxLabel {
          color: $greyLighterPlus;
        }
      }
    }
  }

  &:hover {

    label .CheckboxStyled {
      border-color: $purpleDarker;

      &:before {
        opacity: .5;
      }
    }
  }

  &.Secondary {

    .CheckboxStyled {
      background: $greyLightestPlus;
    }
  }
}

.CheckboxStyled {
  background: $white;
  border: 1px solid $greyLighterPlus;
  border-radius: 4px;
  box-sizing: border-box;
  height: 24px;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all .2s $defaultBezier;
  width: 24px;

  &.ButtonOnTop {
    top: 0;
    transform: none;
  }

  &:before {
    color: $greyDarker;
    font-size: $fontSize-Icon16;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .2s $defaultBezier;
  }
}

.CheckboxLabel {
  color: $greyDarkest;
  cursor: pointer;
  display: inline-block;
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  hyphens: auto;
  line-height: 22px;
  padding-left: 36px;
  position: relative;

  &.Small {
    font-size: $fontSize-s;
    letter-spacing: 0.5px;
    line-height: 20px;
  }

  :global {

    .normal {
      font-weight: $font-weight-m;
    }

    .Active {
      display: block;
      hyphens: auto;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.CheckboxTooltip {
  position: absolute;
  right: -22px;
  top: 3px;
}

@media ( hover: none ) {

  .Checkbox {

    &:hover {

      label .CheckboxStyled {
        border-color: $greyLighterPlus;

        &:before {
          opacity: 0;
        }
      }
    }
  }
}
