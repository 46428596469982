/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Button {
  appearance: none;
  background: $purpleDarker;
  border: 2px solid $purpleDarker;
  border-radius: 24px;
  box-shadow: 0 2px 2px rgba(178, 36, 105, 0.24);
  color: $white;
  cursor: pointer;
  display: block;
  font-family: $fontFamily;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 36px;
  min-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .4s $defaultBezier;
  width: 100%;

  &:hover {
    background: $purple;
    border-color: $purple;
  }

  &:focus {
    border-color: $purpleDarkest;
    outline: none;
  }

  &.ButtonBack {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: $greyDarkest;
    font-size: $fontSize-sm;
    padding: 0;
    text-transform: uppercase;

    &:before {
      color: $purpleDarker;
      font-size: $fontSize-Icon32;
      line-height: 20px;
      position: relative;
      top: 8px;
    }
  }

  &.ButtonLarge {
    font-size: $fontSize-l;
    line-height: 44px;
    min-height: 48px;
    padding: 0 20px;
  }

  &.ButtonSmall {
    font-size: $fontSize-sm;
    line-height: 20px;
    min-height: 32px;
    padding: 4px 10px;
  }

  &.ButtonSecondary {
    background: $asamGreenDarkest;
    border-color: $asamGreenDarkest;

    &:hover {
      background: $asamGreenDarker;
      border-color: $asamGreenDarker;
    }

    &:focus {
      border-color: $asamGreenDarkest;
      outline: none;
    }
  }

  &.ButtonTertiary {
    background: $greyLightestPlus;
    border-color: $greyLightest;
    color: $greyDarkest;
    font-size: $fontSize-sm;
    text-transform: uppercase;

    &:hover {
      background: $greyLightest;
      border-color: $greyMiddle;
    }

    &:focus {
      border-color: $greyMiddle;
      outline: none;
    }

    svg {
      height: 16px;
      vertical-align: -3px;
      width: 16px;
    }
  }

  &.ButtonTertiaryNoCaps {
    background: $greyLightestPlus;
    border-color: $greyLightest;
    color: $greyDarkest;
    font-size: $fontSize-sm;

    &:hover {
      background: $greyLightest;
      border-color: $greyMiddle;
    }

    &:focus {
      border-color: $greyMiddle;
      outline: none;
    }

    svg {
      height: 16px;
      vertical-align: -3px;
      width: 16px;
    }
  }

  &.ButtonCapitalize {
    text-transform: none;
  }

  &.ButtonNoShadow {
    box-shadow: none;
  }

  &.ButtonDisabled {
    background: $greyLightestPlus;
    border-color: $greyLightest;
    box-shadow: none;
    color: $greyLighterPlus;
  }

  &.isLoading {
    background: $greyLightest;
    border-color: $greyLightest;
  }

  &.ButtonTransparentBg {
    background: transparent;
  }

  &.ButtonAsLink {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: $purpleDarker;
    font-weight: $font-weight-m;
    line-height: 1;
    margin-bottom: 0 !important;
    min-height: auto;
    padding: 0;
    text-decoration: underline;
    width: auto !important;

    &:hover {
      opacity: .8;
    }
  }
}

.ButtonIconBefore {
  margin-right: 8px;
}

.ButtonGhost {
  appearance: none;
  background: $white;
  border: 2px solid $purpleDarker;
  border-radius: 24px;
  box-shadow: inset 0 2px 2px rgba(178, 36, 105, 0.24), 0 2px 2px rgba(178, 36, 105, 0.24);
  color: $purpleDarker;
  cursor: pointer;
  display: block;
  font-family: $fontFamily;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 35px;
  min-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .4s $defaultBezier;
  width: 100%;

  &:hover {
    background: $purple;
    border-color: $purple;
    box-shadow: 0 2px 2px rgba(178, 36, 105, 0.24);
    color: $white;
  }

  &:focus {
    background: $purple;
    border-color: $purple;
    box-shadow: 0 2px 2px rgba(178, 36, 105, 0.24);
    color: $white;
    outline: none;
  }

  &.ButtonLarge {
    font-size: $fontSize-l;
    line-height: 44px;
    min-height: 48px;
  }

  &.ButtonSmall {
    font-size: $fontSize-sm;
    line-height: 20px;
    min-height: 32px;
    padding: 4px 10px;
  }

  &.ButtonSecondary {
    border-color: $greyLighter;
    color: $greyLighter;

    &:hover {
      background: $greyLighter;
      border-color: $greyLighter;
      color: $white;
    }

    &:focus {
      background: $greyLighter;
      border-color: $greyLighter;
      color: $white;
    }
  }

  &.ButtonNoShadow {
    box-shadow: none;
  }

  &.ButtonDisabled {
    background: $greyLightestPlus;
    border-color: $greyLightest;
    box-shadow: none;
    color: $greyLighterPlus;
  }

  &.isLoading {
    background: $greyLightest;
    border-color: $greyLightest;
  }

  &.ButtonTransparentBg {
    background: transparent;
  }
}

.ButtonAmazon {
  appearance: none;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.26) 0%, rgba(255, 255, 255, 0) 100%), #f6c655;
  border: 1px solid $gold;
  border-radius: 24px;
  box-shadow: 0 2px 2px rgba(178, 36, 105, 0.24);
  color: $greyDarker;
  cursor: pointer;
  display: block;
  font-family: $fontFamily;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin-left: auto;
  margin-right: auto;
  min-height: 32px;
  padding: 4px 0 4px 35px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: all .4s $defaultBezier;
  width: 82%;

  &:hover {
    opacity: .9;
  }

  svg {
    height: 16px;
    left: 2px;
    position: absolute;
    top: 8px;
    width: 19px;
  }
}

.ButtonFacebook,.ButtonAppleId, .ButtonPayPal, .ButtonAmazonLogin {
  appearance: none;
  background: $black;
  border: none;
  border-radius: 20px;
  color: $white;
  cursor: pointer;
  display: flex;
  font-family: $fontFamily;
  font-size: $fontSize-sm;
  font-weight: $font-weight-m;
  justify-content: center;
  line-height: 24px;
  margin: 15px auto 0;
  max-width: 240px;
  padding: 7px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .4s $defaultBezier;

  i {
    font-size: $fontSize-l;
  }

  &:hover {
    opacity: .9;
  }

  div {
    margin-left: 8px;
    text-align: center;
  }
}

.ButtonFacebook {
  background: $facebook;
}

.ButtonPayPal {
  background: $paypalBlue;
}

.ButtonAmazonLogin {
  background: $amazonYellow;
  color: $greyDarkest;

  &:hover {
    background-color: $amazonYellowHover;
  }
}

.ButtonLessMore {
  appearance: none;
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 24px;
  color: $greyDarkest;
  cursor: pointer;
  display: block;
  font-family: $fontFamily;
  font-size: $fontSize-s;
  font-weight: $font-weight-l;
  height: 40px;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  padding: 5px 15px 4px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .4s $defaultBezier;

  &:hover {
    opacity: .8;
  }

  span {

    &:not(.Loading) {
      padding-left: 24px;
      position: relative;
    }
  }

  :global {

    .before-icon-minus,
    .before-icon-plus {

      &:before {
        color: $purpleDarker;
        font-size: $fontSize-Icon20;
        left: 0;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        transition: all .4s $defaultBezier;
      }
    }

    span.icon-plus {
      color: $purpleDarker;
      display: block;
      font-size: $fontSize-l;
      left: -2px;
      line-height: 23px;
      padding-left: 0;
      position: relative;
    }
  }

  svg {
    height: 16px;
    left: 0;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    transition: all .4s $defaultBezier;
    width: 16px;
  }

  &.isLoading {
    background: $greyLightest;
    border-color: $greyLightest;
  }
}

.ButtonInvisible {
  display: block;
  margin-top: 0 !important;
  max-height: 1px;
  opacity: 0;
  overflow: hidden;
}

.Loading {
  background: $greyLightest;
  border-radius: 24px;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all .4s $defaultBezier;
  width: 100%;

  &:before {

    :global {
      animation: spin .4s infinite linear;
    }
    border: 2px solid $white;
    border-color: $white transparent $white $white;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 50%;
    margin: -8px 0 0 -8px;
    position: absolute;
    top: 50%;
    width: 16px;
  }
}

.ButtonAmazonPay {
  appearance: none;
  background-size: cover;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 45px;
  outline: none;
  transition: all .4s $defaultBezier;
  width: 200px;

  &:hover {
    opacity: .8;
  }
}

.ButtonNextIcon {
  height: 12px;
  line-height: 1;
  margin-top: -7px;
  position: absolute;
  right: 22px;
  top: 50%;
  width: 7px;

  &:after {
    font-size: $fontSize-Icon20;
    line-height: 14px;
  }
}

.ButtonLockIcon {
  left: 13px;
  line-height: 1;
  margin-top: -11px;
  position: absolute;
  top: 50%;

  &:before {
    font-size: $fontSize-Icon20;
  }
}

/* ------------------------------------*\
#Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .Button.ButtonSmallMobile {
    font-size: $fontSize-m;
    line-height: 35px;
    min-height: 39px;
  }
}
