#frontnow-advisor-launcher {
  background-color: #53565A !important;

  div {
    opacity: 1 !important;
  }

  img {
    height: 50px !important;
    object-fit: unset !important;
    width: 50px !important;
  }

}
