/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.imageCaption {
  display: flex;
  flex-flow: wrap row-reverse;
  justify-content: space-between;

  p {
    color: $greyLighter;
    font-size: $fontSize-s;
    letter-spacing: .5px;
    overflow: hidden;
    padding-left: 20px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + p {
      padding-left: 0;
    }
  }
}

.Arrow {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  height: 50px;
  margin-top: -40px;
  outline: none;
  position: absolute;
  top: 50%;
  transition-duration: 0.4s;
  width: 50px;
  z-index: 3;

  &:after {
    display: none;
  }
}

.ArrowPrev {
  background-image: url('../../../../../assets/images/slider/arrow-left.svg');
  left: -5px;

  &:hover {
    background-position-x: -10px;
  }
}

.ArrowNext {
  background-image: url('../../../../../assets/images/slider/arrow.svg');
  right: -5px;

  &:hover {
    background-position-x: 10px;
  }
}

.ImageItem {

  img {
    height: auto;
    width: 100%;
  }
}

@media screen and ( $custom-1080-media ) {

  .ImageItem {

    img {
      max-width: 604px;
    }
  }
}
