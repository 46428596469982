/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.MiniCartIconWrapper {
  float: right;
  margin-left: 35px;
  position: relative;
  width: 64px;

  .MiniCartIcon {
    color: $grey;
    cursor: pointer;
    text-align: center;
    transition: all .3s $defaultBezier;

    a {
      display: block;
      padding-bottom: 15px;

      &:before {
        @extend .icomoon-font !optional;
        content: $iconBasket;
        display: block;
        font-size: 32px;
        margin: 0 auto 6px;
      }
    }
  }

  &:hover {

    .MiniCartIcon {
      color: $purple;
    }
  }
}

.MiniCartIconLink {
  display: none;
}

.MiniCartIndicator {
  background: $asamGreenDarker;
  border-radius: 8px;
  color: $white;
  font-size: 11px;
  font-weight: $font-weight-l;
  height: 16px;
  min-width: 16px;
  padding: 0 5px;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 1px;
}

.MiniCartLabel {
  bottom: 0;
  font-size: $fontSize-s;
  left: 50%;
  letter-spacing: 0.5px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 100px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .MiniCartIconWrapper {
    margin-left: 11px;
    width: auto;

    .MiniCartIcon {

      a {

        &:before {
          margin-bottom: 0;
        }
      }
    }
  }

  .MiniCartLabel {
    display: none;
  }

  .MiniCartIndicator {
    right: -7px;
  }
}

@media screen and ( $mobile-media ) {

  .MiniCartIconWrapper {
    margin-left: 10px;
  }
}
