/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.AddToWishlist {
  cursor: pointer;
  height: 20px;
  opacity: 0;
  position: absolute;
  right: 11px;
  top: 12.5px;
  transition: all .3s $defaultBezier;
  z-index: 4;

  span {

    &:before {
      color: $purpleDarker;
      font-size: $fontSize-Icon16;
    }
  }

  &.Secondary {
    height: auto;
    opacity: 1;
    position: relative;
    right: 0;
    top: 0;

    span {

      &:before {
        color: $purpleDarker;
        font-size: $fontSize-Icon14;
      }
    }
  }

  &.Tertiary {
    height: auto;
    opacity: 1;
    position: relative;
    right: 0;
    top: 0;
  }
}
