/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.SearchAutocomplete {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(33, 34, 36, 0.4);
  color: $greyDarkest;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 30px 12px 32px;
  pointer-events: auto;
  position: absolute;
  top: 90px;
  transform: translateY(-10px);
  transition: all .3s $defaultBezier;
  visibility: hidden;
  width: 100%;

  &.Visible {
    opacity: 1;
    overflow: visible;
    transform: translateY(0);
    visibility: visible;
  }
}

.SearchAutocompleteArrow {
  height: 15px;
  left: 50%;
  position: absolute;
  top: -15px;
  transform: translateX(-50%);
  width: 16px;

  &:after {
    box-shadow: 0 0 5px rgba(33, 34, 36, 1);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-62deg);
    width: 11px;
    z-index: 1;
  }

  &:before {
    box-shadow: 0 0 5px rgba(33, 34, 36, 1);
    content: '';
    height: 1px;
    position: absolute;
    right: 0;
    top: 7px;
    transform: rotate(62deg);
    width: 11px;
    z-index: 1;
  }
}

.SearchAutocompleteArrowBg {
  border-color: $transparent $transparent $white;
  border-style: solid;
  border-width: 0 8px 15px;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 2;
}

.SearchAutocompleteTitle {
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-bottom: 10px;
}

.SearchTerms {
  float: left;
  width: 30.5%;

  .SearchAutocompleteTitle {
    margin-left: 12px;
  }
}

.SearchTermsListItem {
  border-radius: 16px;
  cursor: pointer;
  line-height: 22px;
  padding: 5px 12px;
  transition: all .2s $defaultBezier;

  &:hover {
    background: $purpleDarker;
    color: $white;

    span {
      color: $white;
    }
  }

  span {
    color: $asamGreenDarkest;
  }
}

.Products {
  float: left;
  margin-left: 5.5%;
  width: 64%;
}

.ProductsListItem {
  cursor: pointer;
  overflow: hidden;
  padding: 12px 0;
}

.ProductInfo {
  margin: 10px 0 10px 24px;
}

.ProductImage {
  float: left;
  margin: 0 5px 0 0;

  & > img {
    height: 64px;
    width: 64px;
  }
}

.ProductName {
  font-size: $fontSize-l;
  margin-bottom: 4px;

  span {
    color: $asamGreenDarkest;
  }
}

.ProductAttr {
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.SearchAutocompleteButton {
  clear: both;
  display: inline-block;
  margin-left: 50%;
  min-width: 300px;
  padding-top: 48px;
  transform: translate(-50%, 0px);
  width: auto;
}

.SearchAutocompleteClose {
  cursor: pointer;
  display: none;
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;
  padding-bottom: 5px;
  padding-right: 24px;
  padding-top: 5px;
  position: absolute;
  right: 16px;
  text-align: right;
  text-transform: uppercase;
  top: 13px;

  &:after {
    color: $purpleDarker;
    font-size: $fontSize-Icon25;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.SearchTermNoMatch {
  font-weight: $font-weight-l;
  padding: 12px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .SearchAutocomplete {
    border-radius: 0 0 8px 8px;
    left: -24px;
    padding: 20px 24px 32px;
    top: 56px;
    width: calc(100% + 48px);

    &:after {
      background: #ffffff;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      top: -3px;
      width: 100%;
    }

    .ProductsListItem {
      padding: 5px 0;
    }
  }

  .SearchAutocompleteArrow {
    display: none;
  }

  .SearchTerms {
    display: none;
  }

  .Products {
    margin-left: 0;
    width: 100%;
  }

  .SearchAutocompleteClose {
    display: block;
  }
}

@media screen and ( $mobile-media ) {

  .SearchAutocomplete {
    left: -16px;
    width: calc(100% + 32px);

    .ProductsListItem {
      padding: 0;
    }

    .ProductImage {
      margin-bottom: 20px;
    }
  }
}
