/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.AboWrapper {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
  padding: 20px;
  position: relative;
}

.TextWrapper {
  position: relative;

  p {
    font-size: $fontSize-s;
  }

  ul {
    font-size: $fontSize-sm;

    li {

      &:before {
        content: $iconCheck;
        margin-right: 10px;
        @extend .icomoon-font !optional;
      }
    }
  }

  a {
    font-size: $fontSize-xs;
    text-decoration: underline;
  }
}

.MoreLink {
  cursor: pointer;
  display: block;
  font-size: $fontSize-xs;
  margin-top: 16px;
  text-align: right;
  text-decoration: underline;
}

.AboSelection {
  display: flex;
  margin-bottom: 16px;

  & > * {
    margin-right: 20px;
  }

  :global {

    label {
      position: relative;

      span {
        color: $purpleDarker;
        font-size: $fontSize-s;
        font-weight: $font-weight-m;
        left: 100%;
        position: absolute;
        top: 0;
      }
    }
  }
}

.DisplayIntervals {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  font-size: $fontSize-m;
  justify-content: space-between;
  margin: 16px 0;

  .themed-dropdown {
    margin-bottom: 0;
  }
}
