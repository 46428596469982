/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ShippingCostTooltipContent {
  color: $greyDarkest;

  :global {

    div {
      font-size: $fontSize-sm;
      font-weight: $font-weight-l;
      line-height: 22px;
    }

    ul {
      margin-top: 8px;
    }

    li {
      font-size: $fontSize-sm;
      line-height: 22px;
      margin-bottom: 6px;
      padding-left: 19px;
      position: relative;

      &:before {
        font-size: $fontSize-Icon12;
        left: 3px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
